import { Banner } from '../models/Banner';

export const BANNERS: Banner[] = [
	{
		stepCode: 'carrera',
		imageSrc: 'img/people/CARRERA-PREFERENCIA.png',
	},
	{
		stepCode: 'modalidad',
		imageSrc: 'img/people/MODALIDAD.png',
	},
	{
		stepCode: 'estudios',
		imageSrc: 'img/people/ESTUDIOS-REQUISITOS.png',
	},
	{
		stepCode: 'pago',
		imageSrc: 'img/people/CONFIRMACION-REGISTRO.png',
	},
];
