import classnames from 'classnames';
import type { ChangeEventHandler, FC } from 'react';
import FileInput from '../../atoms/FileInput/FileInput';
import Tooltip from '../Tooltip/Tooltip';

export type RequisitoInputProps = {
  id: string;
  nombre: string;
  descripcion: string;
  className?: string;
  fileName?: string;
  required: boolean;
  mode?: 'grid' | 'lineal';
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const RequisitoInput = ({
  id,
  nombre,
  descripcion,
  className,
  fileName,
  mode = 'grid',
  required,
  onChange
}: RequisitoInputProps) => {
  const selectText = mode === 'grid' ? 'Seleccionar archivo' : 'Seleccionar';
  const replaceText = mode === 'grid' ? 'Seleccionar otro archivo' : 'Cambiar';
  return <div className={classnames(className, '')}>
    <div className={`flex w-full ${mode === 'grid' ? 'md:w-3/4' : ''}`}>
      <label>{required && <strong className='upsjb-color-red'>* </strong>}{nombre}</label>
      <Tooltip>{descripcion}</Tooltip>
    </div>
    <div className={`${mode === 'lineal' && 'w-1/3 flex justify-end'}`}>
      <FileInput mode={mode} fileName={fileName} verified={fileName ? true : false} onChange={onChange}>
        {fileName ? replaceText : selectText}</FileInput>
    </div>
  </div>;
};

export default RequisitoInput;
