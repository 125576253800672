import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import { useEffect, useState } from 'react';
import Modal from '../../molecules/Modal/Modal';
import { useStorageManager } from '../../hooks/useStateManager';
import { useToast } from '../../hooks/useToast';
import usjbClient from '../../sdk';
import { MODALIDAD_KEY } from '../../constants/keys';
import { ModalidadCategoria } from '../../models/ModalidadCategoria';
import { ModalidadStored } from '../../models/ModalidadStored';
const InicioPregrado = () => {
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [aceptarTratamientoDatos, setAceptarTratamientoDatos] = useState(false);
  const storageManager = useStorageManager();
  const [categoriaModalidades, setCategoriaModalidades] = useState<ModalidadCategoria[]>([]);

  const navigate = useNavigate();
  const irAEstudios = () => {
    navigate('/estudios?paso=carrera');
  };
  useEffect(() => {
    const init = async () => {
      const { listaModalidad } =
        await usjbClient.modalidad.obtenerListarCategoriaModalidad();
      setCategoriaModalidades(listaModalidad
        .map((e: ModalidadCategoria) => ModalidadCategoria.fromJSON(e)));
    };
    init();
  }, []);
  return (
    <div className="m-8">

      {false && <p className="text-xl">Elige la opción que prefieras</p>}

      <div className="md:flex justify-center md:justify-around bloque-wrapper">

        <div className="bloqueopciones font-agenda-bold">
          {categoriaModalidades.map((categoria: ModalidadCategoria) => <div key={categoria.id}
            className={`bloquemod ${categoria.color} cursor-pointer`} onClick={() => {
              setShowModal(true);
              storageManager.set(MODALIDAD_KEY, {
                categoria: categoria.id,
                categoriaDescripcion: categoria.nombre,
                idProceso: 0,
                modalidad: "",
                modalidadDescripcion: "",
                turno: "",
                turnoDescripcion: "",
              } as ModalidadStored);
              storageManager.set('tipo_inscripcion', 'regular');
            }}>
            <div className='bloquemodnum'>
              <p>{categoria.identify}</p>
            </div>
            <div className='bloquemodnom'>
              <p>{categoria.nombre}</p>
            </div>
          </div>
          )}
        </div>
      </div>
      <div className="md:flex justify-center md:justify-around">
        {/* <div
          onClick={() => {
            setShowModal(true);
            storageManager.set('tipo_inscripcion', 'pronabec');
          }}
          className="md:w-1/3 cursor-pointer"
        >
          <div className="flex justify-center">
            <img
              className="w-32 md:w-fit"
              src="img/recurso_5.png"
              alt="Pregrado"
            ></img>
          </div>
          <h1 className="text-center text-2xl text-rose-500 font-bold">
            BENEFICIARIO PRONABEC
          </h1>
        </div> */}


        {/*
        <div
          onClick={() => {
            setShowModal(true);
            storageManager.set('tipo_inscripcion', 'regular');
          }}
          className="md:w-1/3 cursor-pointer"
        >
          <div className="flex justify-center">
            <img
              className="w-32 md:w-fit"
              src="img/pregrado.png"
              alt="Pregrado"
            ></img>
          </div>
          <h1 className="text-center text-2xl text-rose-500 font-bold">
            REGULAR
          </h1>
        </div>

      */}

      </div>

      <div className="flex justify-end my-10">
        <div className="flex flex-col">
          {false &&
            <Button href='https://upsjbhelp.freshdesk.com/support/solutions/articles/63000280857--c%C3%B3mo-realizar-inscripci%C3%B3n-de-postulante-upsjb-'
              target='_blank'
              hoverbackgroundcolor='upsjb-turquoise-hovering'
              className="flex justify-center items-center upsjb-turquoise">
              <img
                className="mx-2 self-center"
                src="img/video.png"
                alt="Video"
              ></img>
              Ver instructivo
            </Button>}
        </div>
      </div>

      <Modal
        open={showModal}
        setOpen={setShowModal}
        leftButtonMessage={'Continuar'}
        leftButtonDisabled={!buttonActive}
        showCloseButton={false}
        onClickLeftButton={async () => {
          if (aceptarTratamientoDatos) {
            irAEstudios();
            return true;
          } else {
            addToast(
              'error',
              'Ver términos y condiciones.',
            );
            return false;
          }
        }}
      >
        <h1 className="text-2xl font-bold">Términos y condiciones</h1>
        <div className=''>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            Este sitio web es operado por la Universidad Privada San Juan Bautista
            S.A.C. (en adelante UPSJB S.A.C.) y el uso de los servicios implicará
            que usted ha leído y aceptado los Términos y Condiciones de Uso. En
            caso de no estar de acuerdo con los Términos y Condiciones de Uso
            deberá abstenerse de utilizar nuestros servicios.
          </p>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            Extracto del punto 5 de los términos y condiciones.
          </p>
          <h2 className="text-xl text-left font-bold">Admisión On Line</h2>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            La inscripción de postulantes en línea está dirigida para Diplomados y nivel pregrado en la
            modalidad de examen general de admisión y aplica solo para estudios presenciales. Por el
            momento no contempla la inscripción para las modalidades de examen especial y centro
            preuniversitario.
          </p>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            La inscripción de postulantes en línea implica que la persona que utilice el servicio declara
            haber tomado conocimiento y aceptar las disposiciones del Reglamento de Admisión de la UPSJB S.A.C. Asimismo, acepta que, una vez realizada la inscripción, no tiene derecho a
            solicitar la devolución del pago efectuado por este concepto (si lo hubiera), excepto que la
            admisión del programa se cancele. Asimismo, acepta haber sido informado(a) y acepta que
            la Universidad se reserva el derecho de asignar local y turno de estudios.
          </p>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            La inscripción de postulantes en línea solo deberá ser utilizada por personas que hayan
            concluido sus estudios secundarios; y que no haya sido separado(a) anteriormente del
            Sistema Universitario; de no cumplir con estas condiciones no debe realizar la inscripción.
            En caso de alcanzar una vacante en el proceso de admisión el ingresante está obligado a
            presentar todos los documentos exigidos y cancelar los derechos para el proceso de
            matrícula dentro del plazo establecido en el Cronograma de Actividades Académicas
            vigente, salvo casos fortuitos de impedimento. En caso de incumplimiento, perderá la
            vacante sin derecho a reclamo.
          </p>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            Asimismo, en caso de que el ingresante no efectúe su matrícula dentro del plazo establecido,
            asumirá el derecho correspondiente a la Matrícula Extemporánea.
          </p>
          <p className="text-sm text-gray-500 text-justify my-4 pr-2">
            La inscripción de postulantes en línea implica que la entrega de los requisitos, como:
            Certificado de estudios secundarios, Acta de nacimiento y Copia del DNI, se deben efectuar
            al momento de realizar la respectiva matrícula, salvo casos fortuitos de impedimento.
          </p>
        </div>
        <p className="text-sm text-gray-500 text-justify my-4">
          <a
            onClick={() => {
              setButtonActive(true);
              setAceptarTratamientoDatos(true);
            }}
            download="https://www.upsjb.edu.pe/doc/SIS-OT-04.pdf"
            className="text-blue-400"
            target="_blank" rel="noreferrer"
            href="https://www.upsjb.edu.pe/doc/SIS-OT-04.pdf"
          >
            Ver términos y condiciones.
          </a>{' '}
        </p>
        <div className="flex items-center">
          <input
            className="h-5 w-5 accent-rose-500"
            type="checkbox"
            checked={aceptarTratamientoDatos}
            id="acepto_terminos"
            onChange={(event) => {
              setAceptarTratamientoDatos(event.target.checked);
              if (event.target.checked) {
                //   const link = document.createElement('a');
                //   link.setAttribute("target", "_blank");
                //   link.setAttribute('href', 'https://www.upsjb.edu.pe/doc/SIS-OT-04.pdf');
                //   link.setAttribute('download', 'https://www.upsjb.edu.pe/doc/SIS-OT-04.pdf');
                //   link.click();
                setButtonActive(true);
              }
            }}
          />
          <label
            className="cursor-pointer text-sm text-left ml-3 text-gray-700"
            htmlFor="acepto_terminos"
          >
            He leído y acepto los términos y condiciones y el tratamiento de mis
            datos personales
          </label>
          <br />
        </div>
      </Modal>
    </div>
  );
};

export default InicioPregrado;