export class ModalidadTurno {
    SSR_SHIFT: string;
    DESCR: string;
    SSR_TOTAL_SEATS: string;
    constructor(data: any) {
        this.SSR_SHIFT = data.SSR_SHIFT;
        this.DESCR = data.DESCR.replace('UNICA', '');
        this.SSR_TOTAL_SEATS = data.SSR_TOTAL_SEATS;
    }
    static fromJSON(data: any) {
        return new ModalidadTurno(data);
    }
}
