import { Transition } from '@headlessui/react';
import {
  XIcon,
  XCircleIcon,
  CheckIcon,
  InformationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import { FC, Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useToast } from '../../hooks/useToast';

export type ToastType = 'success' | 'error' | 'warning' | 'info';

export interface ToastProps {
  id: number;
  type: ToastType;
  text: string;
}

const icons: Record<ToastType, typeof XCircleIcon> = {
  success: CheckIcon,
  info: InformationCircleIcon,
  error: XCircleIcon,
  warning: ExclamationIcon,
};
const colors: Record<ToastType, string> = {
  success: 'text-green-500',
  info: 'text-blue-400',
  error: 'text-red-600',
  warning: 'text-orange-500',
};

const Toast: FC<ToastProps> = (props: ToastProps) => {
  const { removeToast } = useToast();
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(!show);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  const Icon = icons[props.type];
  return (
    <Transition
      appear={true}
      show={show}
      as={Fragment}
      afterLeave={() => removeToast(props.id)}
    >
      <Transition.Child
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0 scale-100 "
        leaveTo="opacity-0 scale-95"
      >
        <div
          className="flex items-center p-4 mb-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow-xl"
          role="alert"
        >
          <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 rounded-lg">
            <Icon className={colors[props.type]} width={24} height={24}></Icon>
          </div>
          <div className="ml-3 text-sm font-normal">
            {props.text}
          </div>
          <button
            type="button"
            className="ml-auto -mx-1.5 -my-1.5 hover:text-gray-500 rounded-lg focus:ring-2 focus:ring-gray-500 p-1.5 inline-flex h-8 w-8"
            data-dismiss-target="#toast-success"
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <XIcon
              width={24}
              height={24}
              onClick={() => {
                setShow(!show);
              }}
            />
          </button>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export interface ToastContainerProps {
  toasts: ToastProps[];
}

export const ToastContainer: FC<ToastContainerProps> = (props) => {
  return createPortal(
    <div className="fixed right-4 top-4 z-50">
      {props.toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>,
    document.body,
  );
};

export default Toast;
