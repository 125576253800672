import usjbClient from '../../sdk';

export const obtenerPaises = async () => {
  const { listaParametro: paises } = await usjbClient.parametro.obtenerPais();
  return paises.map(
    ({ texto: text, valor: value }: { texto: string; valor: string }) => ({
      text,
      value,
    }),
  );
};

export const obtenerTiposDocumento = async () => {
  const { listaParametro: documentos } =
    await usjbClient.parametro.obtenerTipoDocumento();
  return documentos.map(
    ({ texto: text, valor: value }: { texto: string; valor: string }) => {
      return {
        text,
        value,
        selected: text === 'DNI',
      };
    },
  );
};

export const obtenerGeneros = async () => {
  const { listaParametro: generos } =
    await usjbClient.parametro.obtenerGenero();
  return generos.map(
    ({ texto: text, valor: value }: { texto: string; valor: string }) => ({
      text,
      value,
    }),
  );
};
