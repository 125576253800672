import { date } from 'yup';
import { addYears, endOfYear } from 'date-fns';

export const MAX_FECHA_NACIMIENTO = endOfYear(addYears(new Date(), -10));

export const fechaNacimientoValidation = date()
  .required()
  .max(
    MAX_FECHA_NACIMIENTO,
    'La fecha de nacimiento mayor que ' + MAX_FECHA_NACIMIENTO.getFullYear(),
  );
