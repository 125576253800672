export const useStorageManager = () => {
  return {
    set<T = any>(key: string, value: T) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    get<T = any>(key: string): T {
      const result = localStorage.getItem(key) ?? 'null';
      return JSON.parse(result);
    },
  };
};
