import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import usjbClient from '../../sdk';
import { useState, useEffect } from 'react';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { ContinueButton } from '../../molecules';
import { useStorageManager } from '../../hooks/useStateManager';
import { ESTUDIOS_KEY, MODALIDAD_KEY } from '../../constants/keys';
import { useToast } from '../../hooks/useToast';
import { ModalidadStored } from '../../models/ModalidadStored';
import { Modalidad } from '../../models/Modalidad';
import SocialMedia from '../../molecules/SocialMedia/SocialMedia';
import {
  categoryPregrado,
  categoryResidentado,
  requestErrorDescription,
  requestErrorTitle,
} from '../../utils/strings';
import { ModalidadTurno } from '../../models/ModalidadTurno';
import { DatosEstudios } from '../../models/DatosEstudios';
import { connectionErrorDialog, endMessageDialog } from '../../utils/dialogs';
import { Select } from '../../atoms/Select/Select';
import { set } from 'lodash';
import Loading from '../Loading/loading';

const ModalidadAdmision = () => {
  const { addToast } = useToast();
  const storageManager = useStorageManager();
  const modalidadStored = storageManager.get<ModalidadStored>(MODALIDAD_KEY);
  const estudiosStored = storageManager.get<DatosEstudios>(ESTUDIOS_KEY);
  const [loading, setLoading] = useState(false);

  const [selectedCategoria, setSelectedCategoria] = useState<string | null>(
    null,
  );
  const [continueClicked, setContinueClicked] = useState<boolean>(false);
  const [continueButton, setContinueButton] = useState<boolean>(false);
  const [modalidades, setModalidades] = useState<Modalidad[]>([]);
  const [listaTurnos, setListaTurnos] = useState<any[]>([]);
  const [listaTurnosSegunda, setListaTurnosSegunda] = useState<any[]>([]);
  const [selectedModalidad, setSelectedModalidad] = useState('');
  const [selectedTurn, setSelectedTurn] = useState('');
  const [selectedTurnSegunda, setSelectedTurnSegunda] = useState('');

  const navigate = useNavigate();
  const obtenerModalidades = async (categoria: string) => {
    const { listModalidad } =
      await usjbClient.modalidad.obtenerListarModalidadByCategoria({
        idCategoria: categoria,
      });
    return listModalidad.map((e: Modalidad) => Modalidad.fromJSON(e));
  };
  const obtenerTurns = async (
    grado: string,
    idCarrera: string,
    idSucursal: string,
    idSemestre: string,
  ) => {
    let listaTurnos: ModalidadTurno[] =
      await usjbClient.modalidad.obtenerTurnos({
        grado,
        idCarrera,
        idSucursal,
        idSemestre,
      });
    return listaTurnos;
  };

  useEffect(() => {
    const init = async () => {
      setSelectedCategoria(modalidadStored.categoria);
      setContinueButton(false);

      const tmpModalidades: Modalidad[] = await obtenerModalidades(
        modalidadStored.categoria,
      );
      setModalidades(tmpModalidades);
    };
    init();
  }, []);

  const allowCheckSegunda = () => {
    return estudiosStored.carrera2daSeleccionada && estudiosStored.carrera2daSeleccionada.length > 0;
  }

  const loadTurnosPrimeraOpcion = async (tmpModalidad: Modalidad) => {
    let turnos: ModalidadTurno[] = [];
    try {
      let tmpTurnos = await obtenerTurns(
        tmpModalidad.grado,
        estudiosStored.carreraSeleccionada,
        estudiosStored.sucursalSeleccionada,
        tmpModalidad.idSemestre,
      );
      if (tmpTurnos) {
        for (let i = 0; i < tmpTurnos.length; i++) {
          if ('SSR_SHIFT' in tmpTurnos[i] && 'DESCR' in tmpTurnos[i]) {
            turnos.push(ModalidadTurno.fromJSON({ ...tmpTurnos[i] }));
          }
        }
      }
    } catch (error) {
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
    }
    setLoading(false);
    if (!turnos || turnos.length <= 0) {
      endMessageDialog(
        'Lo sentimos no se encontraron turnos disponibles.',
      );
      return;
    } else {
      setListaTurnos(turnos);
    }
  }

  const loadTurnosSegundaOpcion = async (tmpModalidad: Modalidad) => {
    let turnos: ModalidadTurno[] = [];
    try {
      let tmpTurnos = await obtenerTurns(
        tmpModalidad.grado,
        estudiosStored.carrera2daSeleccionada,
        estudiosStored.sucursalSeleccionada,
        tmpModalidad.idSemestre,
      );
      if (tmpTurnos) {
        for (let i = 0; i < tmpTurnos.length; i++) {
          if ('SSR_SHIFT' in tmpTurnos[i] && 'DESCR' in tmpTurnos[i]) {
            turnos.push(ModalidadTurno.fromJSON({ ...tmpTurnos[i] }));
          }
        }
      }
    } catch (error) {
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
    }
    setLoading(false);
    if (!turnos || turnos.length <= 0) {
      endMessageDialog(
        'Lo sentimos no se encontraron turnos disponibles.',
      );
      return;
    } else {
      setListaTurnosSegunda(turnos);
    }
  }


  useEffect(() => {
    if (selectedModalidad) {
      const init = async () => {
        setLoading(true);
        const tmpModalidad = modalidades.find(
          ({ id }) => id === selectedModalidad,
        );
        if (tmpModalidad) {
          await loadTurnosPrimeraOpcion(tmpModalidad);
          if (allowCheckSegunda()) {
            await loadTurnosSegundaOpcion(tmpModalidad);
          }

          // console.log('turnos=>', turnos);
        }
      };
      if (modalidadStored.categoria === categoryPregrado.id) {
        init();
      }
    }
  }, [selectedModalidad]);
  return (
    <div className="flex justify-center mb-5">
      <div className="w-10/12 mx-auto">
        <h3 className="text-2xl col-span-2 text-left my-6-per text-rose-500 text-gray-400 text-lg">
          Elige una modalidad y convocatoria:
        </h3>
        {loading && <Loading />}
        <div className="mt-16 mb-4 max-w-100">
          {modalidades.map((modalidad: Modalidad, index) => {
            return (
              <div key={index} className="mb-2">
                <div className="flex items-center gap-x-1">
                  <input
                    className="h-5 w-5 accent-rose-500 cursor-pointer"
                    type="radio"
                    id={`modalidad_${modalidad.id}`}
                    value={modalidad.id}
                    name="modalidad"
                    onClick={() => {
                      setSelectedModalidad(modalidad.id);
                    }}
                  />
                  <label
                    className="mx-2 text-bold text-gray-600"
                    htmlFor={`modalidad_${modalidad.id}`}
                  >
                    <strong>{modalidad.nombre}</strong>
                  </label>
                </div>
                <p className="ml-7 text-gray-400 text-sm text-justify">
                  {modalidad.descripcion}
                </p>
              </div>
            );
          })}
        </div>

        <div className="text-justify">
          <p className="text-gray-600 mb-4">
            {modalidadStored.categoria !== categoryResidentado.id && (
              <span>
                Revisa &nbsp;
                <a
                  href="https://www.upsjb.edu.pe/admision-pregrado/"
                  target="_blank"
                  rel="noreferrer"
                  className="enlace"
                >
                  aquí
                </a>
                &nbsp; las otras modalidades de admisión.
              </span>
            )}{' '}
            Para más información contáctenos a través de nuestros canales de
            atención.
          </p>
          <div className="mb-8">
            <SocialMedia />
          </div>
        </div>
        {modalidadStored.categoria === categoryPregrado.id && (
          <>
            <div className="text-justify mb-4">
              <div>
                <Select
                  required={true}
                  onChange={(event: any) => setSelectedTurn(event.target.value)}
                  className="col-span-6 sm:col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
                  label="Indicar un turno de estudios para la Primera opción"
                  options={(listaTurnos ?? []).map((turno: ModalidadTurno) => {
                    return {
                      value: turno.SSR_SHIFT,
                      text: turno.DESCR,
                    };
                  })}
                />
              </div>
            </div>
            {allowCheckSegunda() &&
              <div className="text-justify mb-4">
                <div>
                  <Select
                    required={true}
                    onChange={(event: any) => setSelectedTurnSegunda(event.target.value)}
                    className="col-span-6 sm:col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
                    label="Indicar un turno de estudios para la Segunda opción"
                    options={(listaTurnosSegunda ?? []).map((turno: ModalidadTurno) => {
                      return {
                        value: turno.SSR_SHIFT,
                        text: turno.DESCR,
                      };
                    })}
                  />
                </div>
              </div>}
            <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md">
              <p className="text-red-600">Importante:</p>
              <p className="nota text-gray-600 text-justify">
                El ingresante será matriculado de manera preferente en el turno
                elegido, siempre y cuando haya disponibilidad de vacantes. En
                caso contrario, se le asignará un turno según el orden
                establecido.
              </p>
              <p className="nota text-gray-600">
                <strong className="upsjb-color-red">*</strong> Campos
                obligatorios.
              </p>
            </div>
          </>
        )}
        <div className="text-justify">
          {modalidadStored.categoria === categoryResidentado.id && (
            <div className="text-gray-600">
              Asegúrese de contar con los siguientes documentos registrados{' '}
              <a
                href="https://8097512.fs1.hubspotusercontent-na1.net/hubfs/8097512/Residentado%20M%C3%A9dico%20pdfs/Ficha%20de%20Control%202023.pdf"
                target="_blank"
                rel="noreferrer"
                className="enlace"
              >
                aquí
              </a>
              .
            </div>
          )}
          {modalidadStored.categoria !== categoryResidentado.id && (
            <div className="text-gray-600">
              Asegúrese de contar con los siguientes documentos para iniciar el
              proceso de inscripción:
              <ul className="list-disc pl-8">
                <li>DNI en formato digital (PDF).</li>
                <li>
                  Certificado Original de Estudios(1) o la Constancia de Logros
                  de Aprendizaje(2) que acredite haber culminado sus estudios
                  secundarios, o de su equivalente en el extranjero, convalidado
                  de acuerdo a Ley. En caso de estar cursando el quinto año de
                  secundaria presentar copia de la libreta de notas (cualquier
                  período) firmada y sellada por el colegio o constancia que
                  acredite estar matriculado en dicho nivel secundario.
                </li>
              </ul>
            </div>
          )}
          <br />
          {modalidadStored.categoria !== categoryResidentado.id && (
            <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md">
              <p className="text-red-600">Nota:</p>
              <p className="nota text-gray-600">
                1. Si cursaste la secundaria antes del 2013, genera tu
                certificado en:&nbsp;
                <a
                  href="https://certificado.minedu.gob.pe"
                  target="_blank"
                  rel="noreferrer"
                  className="enlace"
                >
                  https://certificado.minedu.gob.pe
                </a>
              </p>
              <p className="nota text-gray-600">
                2. Si cursaste la secundaria desde el 2013, genera tu constancia
                en:&nbsp;
                <a
                  href="https://constancia.minedu.gob.pe"
                  target="_blank"
                  rel="noreferrer"
                  className="enlace"
                >
                  https://constancia.minedu.gob.pe
                </a>
              </p>
            </div>
          )}
        </div>

        <div className="flex justify-center gap-x-6">
          <Button
            hoverbackgroundcolor="upsjb-turquoise-hovering"
            onClick={() => {
              navigate('/estudios?paso=carrera');
              if (selectedCategoria !== null) {
                console.log('selectedCategoria=>', selectedCategoria);
                //setModalidades([]);
                //setSelectedCategoria(null);
              } else {
                console.log('ok');
                //navigate(-1);
              }
            }}
            className="flex items-center"
          >
            <ArrowCircleLeftIcon
              className="text-white rounded-full h-8 w-8 hover:shadow mr-4"
              aria-hidden="true"
            />
            Regresar
          </Button>
          {/* {continueButton ? (
          <ContinueButton
              onClick={async () => {
                const modalidades = await obtenerModalidades("001");
                console.log("modalidad =>", modalidades)
                setContinueClicked(true);
              }}
            />
            ) : null} */}
          {selectedCategoria ? (
            <ContinueButton
              onClick={async () => {
                if (selectedModalidad == null) {
                  addToast('error', 'Tiene que seleccionar una modalidad.');
                  return;
                }
                let selectedTurnDescription = '';
                let selectedTurnDescriptionSegunda = '';
                if (modalidadStored.categoria === categoryPregrado.id) {
                  if (selectedTurn === null) {
                    addToast(
                      'error',
                      'Tiene que seleccionar un turno de referencia.',
                    );
                    return;
                  }
                  selectedTurnDescription = listaTurnos.find(
                    (turno: ModalidadTurno) => turno.SSR_SHIFT === selectedTurn,
                  )?.DESCR;
                  if (
                    selectedTurnDescription === undefined ||
                    selectedTurnDescription === ''
                  ) {
                    addToast(
                      'error',
                      'Tiene que seleccionar un turno de referencia.',
                    );
                    return;
                  }
                  ////////////////////////////
                  if (allowCheckSegunda()) {
                    if (selectedTurnSegunda === null) {
                      addToast(
                        'error',
                        'Tiene que seleccionar un turno de referencia para la segunda opcion.',
                      );
                      return;
                    }
                    selectedTurnDescriptionSegunda = listaTurnosSegunda.find(
                      (turno: ModalidadTurno) => turno.SSR_SHIFT === selectedTurnSegunda,
                    )?.DESCR;
                    if (
                      selectedTurnDescriptionSegunda === undefined ||
                      selectedTurnDescriptionSegunda === ''
                    ) {
                      addToast(
                        'error',
                        'Tiene que seleccionar un turno de referencia para la segunda opcion.',
                      );
                      return;
                    }
                  } else {
                    setSelectedTurnSegunda('');
                  }
                } else {
                  setSelectedTurn('');
                  setSelectedTurnSegunda('');
                }
                let modalidadDescripcion = '';
                let categoriaDescripcion = '';
                let idProceso = 0;
                let idSemestre: string = '0';

                setContinueClicked(true);

                const tmpModalidad: Modalidad | undefined = modalidades.find(
                  ({ id }) => id === selectedModalidad,
                );

                if (tmpModalidad) {
                  modalidadDescripcion = tmpModalidad.nombre ?? '';
                  categoriaDescripcion = modalidadStored.categoriaDescripcion;
                  idProceso = tmpModalidad.idProceso ?? 0;
                  idSemestre = tmpModalidad.idSemestre ?? '0';
                } else {
                  addToast('error', 'Tiene que seleccionar una modalidad');
                  return;
                }
                if (modalidadDescripcion && categoriaDescripcion && idProceso) {
                  storageManager.set(MODALIDAD_KEY, {
                    categoria: selectedCategoria,
                    categoriaDescripcion: categoriaDescripcion,
                    idProceso: idProceso,
                    modalidad: selectedModalidad,
                    idSemestre: idSemestre,
                    modalidadDescripcion,
                    turnoSegundaOpcion: selectedTurnSegunda,
                    turnoSegundaOpcionDescripcion: selectedTurnDescriptionSegunda,
                    turno: selectedTurn,
                    turnoDescripcion: selectedTurnDescription,
                  } as ModalidadStored);

                  navigate('/datos-personales?paso=estudios');
                } else {
                  addToast('error', 'Tiene que seleccionar una modalidad');
                }
              }}
            />
          ) : null}
          {/* {modalidades.length >= 2 && continueClicked ? (
            <ContinueButton
              onClick={() => {
                console.log('boton 2')
                console.log("selectedModalidad=>", selectedModalidad)
                if (selectedModalidad) {
                  const modalidadDescripcion = modalidades.find(
                    ({ id }) => id === selectedModalidad,
                  )?.nombre;

                  console.log("modalidades=>", modalidades)

                  const idProceso = modalidades.find(
                    ({ id }) => id === selectedModalidad,
                  )?.idProceso;

                  console.log("idProceso=>", idProceso)

                  storageManager.set(MODALIDAD_KEY, {
                    categoria: selectedCategoria,
                    categoriaDescripcion: modalidadStored.categoriaDescripcion,
                    modalidad: selectedModalidad,
                    modalidadDescripcion,
                    idProceso: idProceso
                  });
                  navigate('/datos-personales?paso=estudios');
                } else {
                  addToast('error', 'Tiene que seleccionar una modalidad');
                }
              }}
            />
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default ModalidadAdmision;
