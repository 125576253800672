import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Este campo es requerido',
    notType: (_ref) => {
      switch (_ref.type) {
        case 'number':
          return 'Debe ser un número válido';
        case 'string':
          return 'Debe ser un texto válido'
        default:
          return 'Tipo de valor inválido'
      }
    },
  },
  string: {
    length: ({ length }) => `Debe tener ${length} caracteres`,
    max: ({ max }) => `Debe tener como máximo ${max} caracteres`,
    min: ({ min }) => `Debe tener como mínimo ${min} caracteres`,
    email: 'No es un correo válido',
  },
  number: {
    min: ({ min }) => `Debe ser ${min} como mínimo`,
    max: ({ max }) => `Debe ser ${max} como máximo`,
    integer: () => `Debe ser un un número entero válido`,
  },
});
