export const regexCellphone = /^9[0-9]{8}$/;
export const homePath = '/admision';
export const modalityIAT = {
  idProceso: parseInt(process.env.REACT_APP_MODALITY_IAT_PROCESS_ID ?? '0'),
};
export const categoryPregrado = {
  id: '001',
};
export const categoryResidentado = {
  id: '002',
  idCarrera: 'RMED',
};
export const schoolWidgetTexts = {
  label: 'Nombre de la Institución Educativa',
  toolTip: 'En caso de no encontrar su colegio, comuníquese al 950-322-888',
  placeholder: 'Escriba el nombre de su colegio',
};
export const universityWidgetTexts = {
  label: 'Nombre de la Universidad',
  toolTip: 'En caso de no encontrar su Universidad, comuníquese al 950-322-888',
  placeholder: 'Escriba el nombre de la Universidad',
};
export const documentTypeDNI = '01';
export const documentTypePASSPORT = '07';
export const documentTypeCE = '04';

export const buttonColorPink = '#F43F5E';
export const buttonColorGray = '#9ca3af';

export const connectionErrorDescription =
  'Hubo un problema al comunicarse con el servidor.';
export const connectionErrorTitle = 'Error de conectividad';
export const requestErrorTitle = 'Error de solicitud';
export const requestErrorDescription =
  'Hubo un error al realizar la solicitud.';
