import { forwardRef, HTMLProps, useId } from 'react';
import type { FieldError } from 'react-hook-form';
import '../Input.css';

export interface Option {
  text: string;
  value: string;
  selected?: boolean;
}

export interface SelectProps {
  options?: Option[];
  label?: string;
  extralabel?: string;
  error?: FieldError;
  required?: boolean;
}

export const Select = forwardRef<
  HTMLSelectElement,
  SelectProps & HTMLProps<HTMLSelectElement>
>((props: SelectProps & HTMLProps<HTMLSelectElement>, ref) => {
  const selectId = useId();
  return (
    <div className={props.className}>
      <div>
        {props.label && <label htmlFor={selectId}>
          {props.label}
          {props.required && <strong className='upsjb-color-red'>*</strong>}
        </label>}
      </div>
      <div className="text-sm antialiased font-light text-zinc-500">
        {props.extralabel ?? null}
      </div>
      <select {...props} id={selectId} ref={ref} className="Input capitalize" autoComplete='off'>
        <option value="" className="text-gray-400">
          Seleccionar...
        </option>
        {props.options?.map((option: Option, index: number) => (
          <option key={index} value={option.value} className="uppercase">
            {option.text}
          </option>
        ))}
      </select>
      <div className="text-sm text-red-500 h-2 mt-2">
        {props.error?.message}
      </div>
    </div>
  );
});
Select.defaultProps = {
  required: false,
};