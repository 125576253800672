import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '../../atoms/Autocomplete/Autocomplete';
import Button from '../../atoms/Button/Button';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { ARCHIVO_REGEX } from '../../constants/archivo';
import {
  DATOS_PERSONALES_KEY,
  MODALIDAD_KEY,
  REQUISITOS_KEY,
} from '../../constants/keys';
import { useStorageManager } from '../../hooks/useStateManager';
import { useToast } from '../../hooks/useToast';
import { Requisito, RequisitoDAO } from '../../models/Requisito';
import { ArchivoRequisitoExtendido } from '../../models/RequisitoRequest';
import { ContinueButton } from '../../molecules';
import RequisitoInput from '../../molecules/RequisitoInput/RequisitoInput';
import usjbClient from '../../sdk';
import {
  DatosRequisitos,
  DatosRequisitosExtendidos,
  requisitosValidation,
} from '../../validations/requisitos.validation';
import {
  categoryResidentado,
  homePath,
  modalityIAT,
  schoolWidgetTexts,
  universityWidgetTexts,
} from '../../utils/strings';
import { ModalidadStored } from '../../models/ModalidadStored';
import Loading from '../Loading/loading';
import { IndexService, RequirementFiles } from '../../services/index.service';
import { DatosPersonalesExtendidos } from '../../validations/datos-personales.validation';
import { useLiveQuery } from 'dexie-react-hooks';

const Requisitos = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<DatosRequisitos>({
    resolver: yupResolver(requisitosValidation),
    mode: 'onBlur',
  });
  const storageManager = useStorageManager();
  const datosPersonalesStored =
    storageManager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);
  const requisitosStored =
    storageManager.get<DatosRequisitosExtendidos>(REQUISITOS_KEY);
  const modalidadStored = storageManager.get<ModalidadStored>(MODALIDAD_KEY);
  // const archivosRequisitosStored = storageManager.get<
  //   ArchivoRequisitoExtendido[]
  // >(ARCHIVOS_REQUISITOS_KEY);

  const archivosRequisitosStored = useLiveQuery(() =>
    IndexService.requirements
      .where({ dni: datosPersonalesStored.numeroDocumento })
      .toArray(),
  );

  const [loading, setLoading] = useState(false);
  const [placeholderLugarColegio, setPlaceholderLugarColegio] =
    useState<string>(
      modalidadStored.categoria === categoryResidentado.id
        ? 'Escriba la ubicación de la Universidad'
        : 'Escriba la ubicación de su colegio',
    );
  const [stringsForSchoolWidget, setStringsForSchoolWidget] = useState<any>(
    modalidadStored.categoria === categoryResidentado.id
      ? { ...universityWidgetTexts }
      : { ...schoolWidgetTexts },
  );
  const { addToast } = useToast();
  // const [lugarColegioCodigo, setLugarColegioCodigo] = useState<string>('');
  const [requisitos, setRequisitos] = useState<Requisito[]>([]);
  const [anioFinColegioEditable, setAnioFinColegioEditable] =
    useState<boolean>(true);
  const [requisitosData, setRequisitosData] = useState<
    Record<string, ArchivoRequisitoExtendido | undefined>
  >({});
  const [lugaresColegios, setLugaresColegios] = useState<any[]>([]);
  const [colegios, setColegios] = useState<any[]>([]);
  const navigate = useNavigate();
  // const lugarColegioTextWatcher = watch('lugarColegioText');
  const [prevDefaultColegio, setPrevDefaultColegio] = useState<any>(
    requisitosStored?.colegioId,
  );
  const [prevDefaultLugar, setPrevDefaultLugar] = useState<any>(
    requisitosStored?.lugarColegio,
  );
  const [defaultColegio, setDefaultColegio] = useState<any>();
  const [defaultLugar, setDefaultLugar] = useState<any>();

  const mapValues = (formValues: DatosRequisitos) => {
    // const colegioIdDescripcion = coleetgios.find(
    //   ({ value }) => value === formValues.colegioId,
    // )?.text;
    const lugarColegioDescripcion = lugaresColegios.find(
      ({ value }) => value === formValues.lugarColegio,
    )?.text;
    //let colegioId = undefined;
    let colegioIdDescripcion = undefined;

    const found = colegios.find(
      (col: any) => col.value === formValues.colegioId,
    );
    if (found) {
      //  colegioId = found.value ?? '';
      colegioIdDescripcion = found.text ?? '';
    }

    return {
      ...formValues,
      colegioIdDescripcion,
      lugarColegioDescripcion,
    };
  };
  const isRequisitosFilled = (): boolean => {
    const requisitosLlenos = Object.values(requisitosData);
    return (
      requisitosLlenos.length === requisitos.length &&
      requisitosLlenos.filter((w) => w?.required).every((r: any) => r?.fileName)
    );
  };

  const getRequisitosContents = (includeEmpties: boolean): any[] => {
    if (includeEmpties) {
      return Object.values(requisitosData);
    } else {
      return Object.values(requisitosData).filter((r: any) => r?.base64);
    }
  };

  const getRequisitoFile = (requisitoId: string): string | undefined => {
    let ff = requisitosData[requisitoId];
    if (ff) {
      return ff.fileName;
    }
    return undefined;
  };

  const getStoredRequisito = (requisitoId: string): any | undefined => {
    if (archivosRequisitosStored) {
      let _file = archivosRequisitosStored.find(
        (e: RequirementFiles) => e.idRequisito == requisitoId,
      );
      // console.log("======", _file, "|", requisitoId);

      if (_file && _file.fileName && _file.contentType && _file.base64) {
        return _file;
      }
    }
    return undefined;
  };
  const onSubmit = async () => {
    let isValidRunning = false;

    try {
      await requisitosValidation.validate(mapValues(getValues()));
      isValidRunning = true;
    } catch (error) {
      isValidRunning = false;
    }

    if (!isValidRunning) {
      addToast('warning', 'Por favor completa los datos correctamente');
      return;
    }
    console.log('>', modalidadStored.idProceso, modalityIAT.idProceso);
    if (modalidadStored.idProceso === modalityIAT.idProceso) {
    } else {
      if (!isRequisitosFilled()) {
        addToast('warning', 'Por favor completa los requisitos correctamente');
        return;
      }
    }
    storageManager.set(REQUISITOS_KEY, mapValues(getValues()));
    if (modalidadStored.idProceso === modalityIAT.idProceso) {
      storeRequirementsInIndex(getRequisitosContents(true));
      // storageManager.set(ARCHIVOS_REQUISITOS_KEY, );
    } else {
      storeRequirementsInIndex(getRequisitosContents(false));
      // storageManager.set(ARCHIVOS_REQUISITOS_KEY, getRequisitosContents(false));
    }
    navigate('/resumen?paso=pago');
  };

  const storeRequirementsInIndex = async (
    files: ArchivoRequisitoExtendido[],
  ) => {
    IndexService.requirements.clear();
    for (let i = 0; i < files.length; i++) {
      IndexService.requirements.add({
        dni: datosPersonalesStored.numeroDocumento ?? '',
        idRequisito: `${files[i].idRequisito}`,
        nombreRequisito: files[i].nombreRequisito,
        base64: files[i].base64,
        contentType: files[i].contentType,
        fileName: files[i].fileName ?? '',
      });
    }
  };

  const fillFileContent = (
    idRequisito: string,
    nombreRequisito: string,
    base64: string,
    contentType: string,
    fileName: string,
  ) => {
    setRequisitosData(() => ({
      ...requisitosData,
      [idRequisito]: {
        idRequisito: parseInt(idRequisito),
        nombreRequisito,
        base64,
        contentType,
        required: requisitosData[idRequisito]?.required ?? false,
        fileName,
      },
    }));
  };

  const onRequisitoChange = (
    idRequisito: string,
    nombreRequisito: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target) {
      console.log('llena vacio');
      fillFileContent(idRequisito, nombreRequisito, '', '', '');
      return;
    }
    const file = event.target?.files?.[0];
    if (!file) {
      console.log('llena vacio');
      fillFileContent(idRequisito, nombreRequisito, '', '', '');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrl = reader.result?.toString();
      if (!reader.result) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, contentType, base64] = dataUrl?.match(ARCHIVO_REGEX) || [];

      const fileName = file.name;

      console.log('llena contenido');
      fillFileContent(
        idRequisito,
        nombreRequisito,
        base64,
        contentType,
        fileName,
      );
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const init = async () => {
      if (!modalidadStored || !modalidadStored.categoria) {
        window.location.href = homePath;
        return;
      }

      const { listaUbigeo } = await usjbClient.ubigeo.obtenerUbigeo();
      setLugaresColegios(
        listaUbigeo.map(
          ({
            ubigeo: text,
            valor: value,
          }: {
            ubigeo: string;
            valor: string;
          }) => ({
            text,
            value,
          }),
        ),
      );

      // IAT
      if (modalityIAT.idProceso === modalidadStored.idProceso) {
        setValue('anioFinColegio', 2024);
        setAnioFinColegioEditable(false);
        // idSemestre
      } else {
        setValue('anioFinColegio', new Date().getFullYear());
        setAnioFinColegioEditable(true);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const doit = async () => {
      console.log('requisitosStored', archivosRequisitosStored);
      if (archivosRequisitosStored) {
        const { listRequisito } =
          await usjbClient.modalidad.obtenerListarRequisito({
            idModalidad: modalidadStored.modalidad,
          });

        setRequisitos(
          listRequisito.map((e: RequisitoDAO) => {
            return {
              id: e.idRequisitoModalidad,
              nombre: e.nombreRequisito,
              descripcion: e.descripcionRequisito,
              required:
                modalidadStored.idProceso === modalityIAT.idProceso
                  ? false
                  : e.obligatoriedad === 1
                  ? true
                  : false,
            };
          }),
        );

        let tmpRequisito: Record<
          string,
          ArchivoRequisitoExtendido | undefined
        > = {};
        listRequisito.forEach((requisito: RequisitoDAO) => {
          let req: any = getStoredRequisito(requisito.idRequisitoModalidad);

          tmpRequisito[requisito.idRequisitoModalidad] = {
            nombreRequisito: requisito.nombreRequisito,
            idRequisito: parseInt(requisito.idRequisitoModalidad),
            base64: req?.base64 ?? '',
            contentType: req?.contentType ?? '',
            required: requisito.obligatoriedad === 1 ? true : false,
            fileName: req?.fileName ?? '',
          };
        });
        setRequisitosData(tmpRequisito);
      }
    };
    doit();
  }, [archivosRequisitosStored]);

  useEffect(() => {
    if (colegios && colegios.length > 0) {
      let found = colegios.find(
        (colegio) => colegio.value === prevDefaultColegio,
      );
      if (found) {
        setDefaultColegio({ ...found, programmatic: true });
        setPrevDefaultColegio(undefined);
      } else {
        setValue('colegioId', '');
      }
    }
  }, [colegios]);

  const fetchColegios = (code: any) => {
    if (code) {
      const init = async () => {
        setLoading(true);
        const { listaColegio } =
          await usjbClient.colegio.obtenerColegioPorUbigeo({
            valor: code,
          });

        setColegios(
          listaColegio.map((colegio) => ({
            text: colegio.NombreColegio,
            value: String(colegio.ColegioId),
          })),
        );
        setLoading(false);
      };
      init();
    }
  };

  useEffect(() => {
    if (lugaresColegios.length > 0) {
      const found = lugaresColegios.find(
        (lugar) => lugar.value === prevDefaultLugar,
      );
      if (found) {
        setDefaultLugar({ ...found, programmatic: true });
        setPrevDefaultLugar(undefined);
      }
    }
  }, [lugaresColegios]);

  const handleComboColegio = (selectedItem: any) => {
    if (selectedItem && selectedItem.value && selectedItem.text) {
      if (selectedItem.programmatic) {
        setValue('colegioId', selectedItem.value ?? '');
      }
    }
  };
  const handleComboLugares = (selectedItem: any) => {
    if (selectedItem && selectedItem.value && selectedItem.text) {
      let found: any = undefined;
      // //if (lugarColegioTextWatcher.split("/").length > 1) {
      //   found = lugaresColegios.find((lugar: any) => lugar.text === lugarColegioTextWatcher);
      //   console.log("regresand", found);
      // } else {
      //   found = lugaresColegios.find((lugar: any) => lugar.value === lugarColegioTextWatcher);
      //   console.log("primera ves", found);
      // }
      // if (found) {
      //setValue('lugarColegio', found.value ?? '');
      if (selectedItem.programmatic) {
        setValue('lugarColegio', selectedItem.value ?? '');
      }
      fetchColegios(selectedItem.value);
      //}
    }
  };

  return (
    <div className="flex justify-center">
      <div className="w-10/12 mx-auto">
        <h3 className="text-2xl col-span-2 text-left my-6-per text-rose-500 text-gray-400 text-lg">
          Institución Educativa de Procedencia:
        </h3>
        {loading && <Loading />}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-2 gap-4 items-center mt-5 pt-10"
        >
          <Autocomplete
            required={true}
            defaultSelected={defaultLugar}
            className="col-span-2"
            label="Ubicación (Ubigeo)"
            options={lugaresColegios}
            placeholder={placeholderLugarColegio}
            error={errors.lugarColegio}
            {...register('lugarColegio')}
            onSelect={handleComboLugares}
          />
          <Autocomplete
            required={true}
            defaultSelected={defaultColegio}
            className="col-span-2"
            label={stringsForSchoolWidget.label}
            toolTip={stringsForSchoolWidget.toolTip}
            options={colegios}
            placeholder={stringsForSchoolWidget.placeholder}
            error={errors.colegioId}
            {...register('colegioId')}
            onSelect={handleComboColegio}
          />

          <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md col-span-2">
            <p className="text-red-600">Nota:</p>
            <p className="nota text-justify text-gray-600">
              En caso no encontrar tu{' '}
              {modalidadStored.categoria === categoryResidentado.id ? (
                <span>universidad </span>
              ) : (
                <span>colegio </span>
              )}
              escríbanos a{' '}
              <a className="enlace" href="mailto:admision@upsjb.edu.pe">
                admision@upsjb.edu.pe
              </a>{' '}
              indicando sus datos de contacto para que un ejecutivo se comunique
              a la brevedad.
            </p>
          </div>

          {modalidadStored.categoria !== categoryResidentado.id && (
            <TextInput
              readOnly={!anioFinColegioEditable}
              className="col-span-2"
              label="Año de finalización de estudios"
              placeholder="Ejemplo: 2021"
              error={errors.anioFinColegio}
              {...register('anioFinColegio')}
            />
          )}

          <h3 className="text-2xl col-span-2 mt-2 text-left my text-rose-500 text-gray-400 text-lg">
            Carga de requisitos:
          </h3>

          {false &&
            requisitos?.map((requisito) => {
              return (
                <RequisitoInput
                  onChange={(event) =>
                    onRequisitoChange(requisito.id, requisito.nombre, event)
                  }
                  {...requisito}
                  key={requisito.id}
                  fileName={getRequisitoFile(requisito.id)}
                  className="col-span-2 md:col-span-1 flex-col flex items-center"
                />
              );
            })}
          <div className="col-span-2 divide-y px-4">
            {true &&
              requisitos?.map((requisito) => {
                return (
                  <RequisitoInput
                    onChange={(event) =>
                      onRequisitoChange(requisito.id, requisito.nombre, event)
                    }
                    {...requisito}
                    key={requisito.id}
                    required={requisito.required}
                    fileName={getRequisitoFile(requisito.id)}
                    mode="lineal"
                    className="flex pt-4 pb-4"
                  />
                );
              })}
          </div>
          <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md col-span-2">
            <p className="text-red-600">Importante:</p>
            <p className="nota text-gray-600 text-justify">
              Todos los requisitos cargados en esta plataforma serán{' '}
              <strong>validados</strong>.
            </p>
            {modalidadStored.categoria === categoryResidentado.id && (
              <p className="nota text-gray-600 text-justify">
                Si se encuentra alguna observación, serás notificado de
                inmediato. Si no se atienden estas observaciones, no podrás
                continuar en el proceso de Admisión al Residentado Médico 2024.
              </p>
            )}
            {modalidadStored.categoria !== categoryResidentado.id && (
              <p className="nota text-gray-600 text-justify">
                Si se encuentra alguna observación, serás notificado de
                inmediato. Si no se atienden estas observaciones, no podrás
                rendir ninguna evaluación.
              </p>
            )}
            <p className="nota text-gray-600 text-justify">
              En caso de que no hayas podido cargar alguno de los requisitos, te
              enviaremos una comunicación desde el correo de{' '}
              <a className="enlace">
                {modalidadStored.categoria === categoryResidentado.id
                  ? 'residentado.medico@upsjb.edu.pe'
                  : 'counter@upsjb.edu.pe'}
              </a>{' '}
              con un enlace para que puedas regularizarlo.
            </p>
            <div>
              <p className="text-red-600">Nota:</p>
              <p className="nota text-gray-600">
                <strong className="upsjb-color-red">*</strong> Campos
                obligatorios.
              </p>
            </div>
          </div>

          <div className="flex justify-center my-8 gap-x-8 col-span-2">
            <Button
              hoverbackgroundcolor="upsjb-turquoise-hovering"
              type="button"
              onClick={() => {
                console.log('isRequisitosFilled', isRequisitosFilled());
                console.log('empties', getRequisitosContents(true));
                console.log('no empties', getRequisitosContents(false));
                //return;
                navigate('/datos-personales?paso=estudios');
              }}
              className="flex items-center"
            >
              <ArrowCircleLeftIcon
                className="text-white rounded-full h-8 w-8 hover:shadow mr-4"
                aria-hidden="true"
              />
              Regresar
            </Button>
            <ContinueButton type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Requisitos;
