import { ButtonHTMLAttributes, FC } from 'react';
import './Button.css';
import classNames from 'classnames';
export interface ButtonProps {
  href?: string;
  target?: string;
  backgroundcolor?: string;
  hoverbackgroundcolor?: string;
}

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = (
  props: ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps,
) => {
  const handleOnClick = (args: any) => {
    props.onClick?.(args);
    if (props.href) {
      window.location.href = props.href;
    }
  };
  return (
    <button
      {...props}
      className={classNames('Button', props.backgroundcolor, 
        props.hoverbackgroundcolor, props.className)}
      onClick={handleOnClick}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  backgroundcolor: 'bg-gray-400',
  hoverbackgroundcolor: 'hover:bg-gray-200'
};

export default Button;
