export class Modalidad {
    idModalidad: string;
    nombreModalidad: string;
    descripcionModalidad: string;
    idProceso: number;
    idSemestre: string;
    idConvocatoria: string;
    grado: string;
    constructor(data: any) {
        this.idModalidad = data.idModalidad;
        this.nombreModalidad = data.nombreModalidad;
        this.descripcionModalidad = data.descripcionModalidad;
        this.idProceso = data.idProceso;
        this.idSemestre = data.idSemestre;
        this.idConvocatoria = data.idConvocatoria;
        this.grado = data.grado;
    }
    static fromJSON(data: any) {
        return new Modalidad(data);
    }
    public get nombre(): string {
        return this.nombreModalidad;
    }
    public get id(): string {
        return this.idModalidad;
    }
    public get descripcion(): string {
        return this.descripcionModalidad;
    }
}
