import Swal from 'sweetalert2';
import { buttonColorPink, homePath } from './strings';

export const endMessageDialog = (html: string) => {
  Swal.fire({
    title: '<strong>Lo sentimos</strong>',
    html: `<div class='text-justify'>${html}</div>`,
    icon: 'info',
    allowOutsideClick: false,
    allowEscapeKey: false,
    reverseButtons: true,
    confirmButtonColor: buttonColorPink,
    confirmButtonText: `
            <a href='${homePath}' target='_self'>Terminar</a>
          `,
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = homePath;
    }
  });
};

export const connectionErrorDialog = (title: string, message: string) => {
  // La solicitud no recibió respuesta del servidor
  Swal.fire({
    icon: 'error',
    title: title,
    text: message,
    showConfirmButton: true,
    confirmButtonColor: buttonColorPink,
    confirmButtonText: `
            <a href='${homePath}' target='_self'>Inicio</a>
          `,
    showCancelButton: false, // Agrega un botón de cancelar
    cancelButtonText: 'Cerrar', // Etiqueta del botón de cancelar
  }).then((result) => {
    if (result.isConfirmed) {
      // Si el usuario hace clic en "Aceptar" (confirmar), redirige a la página de inicio
    }
  });
};
