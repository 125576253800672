import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal from '../../molecules/Modal/Modal';

const ResumenPago = () => {
  const { nrorecibo, fecha, mensaje } = useParams();

  const [showModal, setShowModal] = useState(mensaje === 'Pago Satisfactorio.');
  const navigate = useNavigate();
  const {
    formState: { errors },
  } = useForm({ mode: 'all' });
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const init = async () => {
      MySwal.fire({
        title:
          '<h2 class="text-xl col-span-2 text-center my-6 text-rose-500">RESUMEN DE PAGO</h2>',
        html: `
          <div class="confirmation-box border p-6 mt-6">
            <p class="mb-4">¡Resumen de Transacción!</p>
            <p><strong>Fecha y Hora de la Transacción:</strong>  ${fecha}</p>
            <p><strong>Número de Recibo:</strong>  ${nrorecibo}</p>
            <p><strong>Mensaje:</strong> ${mensaje}</p>
          </div>
        `,
        icon: 'info',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/');
        }
      });
    };
    // init();
  }, []);
  return (
    <>
      <div className="flex justify-center">
        <div>
          <h2 className="text-xl col-span-2 text-center my-6 text-rose-500">
            RESUMEN DE PAGO
          </h2>
          <h2 className="text-2xl col-span-2 text-center my-6">
            <strong>Resumen de Transacción</strong>
          </h2>
          <div className="confirmation-box border p-6 mt-6">
            {/* <p className="mb-4">¡Tu pago ha sido procesado exitosamente!</p> */}
            <p>
              <strong>Fecha y Hora de la Transacción:</strong> {fecha}{' '}
            </p>
            <p>
              <strong>Número de Recibo:</strong> {nrorecibo}{' '}
            </p>
            <p>
              <strong>Mensaje:</strong> {mensaje}
            </p>
          </div>
        </div>

        <Modal
          width={'sm:max-w-2xl'}
          height={'h-svh-70'}
          open={showModal}
          setOpen={setShowModal}
          leftButtonMessage={'Terminar'}
          leftButtonDisabled={false}
          showCloseButton={false}
          checkIcon={false}
          onClickLeftButton={async () => {
            window.location.href = 'https://www.upsjb.edu.pe/';
            return true;
          }}
        >
          <div className="">
            <div className="">
              <img className="w-full" src="img/Img-motivadoraC.png" />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ResumenPago;
