import { boolean, object, string } from 'yup';
import type { InferType } from 'yup';
import { TipoDocumento } from '../models/TipoDocumento';
import { numeroDocumentoValidation } from './numero-documento.validation';
import { celularValidation } from './celular.validation';
import { fechaNacimientoValidation } from './fecha-nacimiento.validation';

export const datosPersonalesValidation = object({
  tipoDocumento: string().oneOf(Object.values(TipoDocumento)).required(),
  numeroDocumento: string().required().when('tipoDocumento', (tipoDocumento, schema) =>
    numeroDocumentoValidation(tipoDocumento)
  ),
  celular: celularValidation,
  // codigoVerificacionCelular: string().required(),
  celularVerificado: string().matches(/^[A-Za-z0-9 .,áéíóúÁÉÍÓÚüÜñÑ]+$/, "Caracteres inválidos"),
  // codigoVerificacionCorreo: string().required(),
  correoVerficado: string(),
  correoElectronico: string().email().required(),
  genero: string().required(),
  lugarNacimiento: string().when('paisNacimiento', {
    is: "PER",
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  nombres: string().required().matches(/^[A-Za-z .,áéíóúÁÉÍÓÚüÜñÑ']+$/, "Caracteres inválidos"),
  paisNacimiento: string().required(),
  primerApellido: string().required().matches(/^[A-Za-z .,áéíóúÁÉÍÓÚüÜñÑ']+$/, "Caracteres inválidos"),
  segundoApellido: string().required().matches(/^[A-Za-z .,áéíóúÁÉÍÓÚüÜñÑ']+$/, "Caracteres inválidos"),
  fechaNacimiento: fechaNacimientoValidation,
  tipoVia: string().required(),
  domicilio: string().required().matches(/^[A-Za-z0-9 .,áéíóúÁÉÍÓÚüÜñÑ]+$/, "Caracteres inválidos").max(55, "El domicilio no puede tener más de 55 caracteres"),
  tipoZona: string().optional(),
  domicilioZona: string().optional().max(55, "La zona no puede tener más de 55 caracteres"),
  referencia: string().optional().max(55, "La referencia no puede tener más de 55 caracteres"),
  tieneDiscapacidad: boolean().required(),
  descripcionDiscapacidad: string(),
  ubigeoDomicilio: string().required(),
});


export interface DatosPersonales
  extends InferType<typeof datosPersonalesValidation> { }

export interface DatosPersonalesExtendidos extends DatosPersonales {
  tipoDocumentoDescripcion: string;
  generoDescripcion: string;
  lugarNacimientoDescripcion: string;
  tipoViaDescripcion: string;
  tipoZonaDescripcion: string;
  paisNacimientoDescripcion: string
  lugarDomicilioDescripcion: string
  codigoPais: string
  DescripcionPais: string
  codigoLugarNacimiento: string
  DescripcionLugarNacimiento: string
  codigoLugarResidencia: string
  DescripcionLugarResidencia: string
}
