export const TIPO_DOCUMENTOS = [
	{
		text: 'DNI',
		value: 'DNI',
		selected: true
	},
	{
		text: 'Carné de Extranjería',
		value: 'CE',
	},
];
