import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import { Select } from '../../atoms/Select/Select';
import usjbClient from '../../sdk';
import { ContinueButton } from '../../molecules';
import { useStorageManager } from '../../hooks/useStateManager';
import {
  DATOS_PERSONALES_KEY,
  ESTUDIOS_KEY,
  MODALIDAD_KEY,
} from '../../constants/keys';
import { useToast } from '../../hooks/useToast';
import { DatosEstudios } from '../../models/DatosEstudios';
import { DatosPersonalesExtendidos } from '../../validations/datos-personales.validation';
import { ModalidadStored } from '../../models/ModalidadStored';
import { categoryResidentado } from '../../utils/strings';

const Estudios = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const storageManager = useStorageManager();
  const datosPersonales =
    storageManager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);
  const modalidadStored = storageManager.get<ModalidadStored>(MODALIDAD_KEY);

  const estudios = storageManager.get<DatosEstudios>('ESTUDIOS');
  const [carreras, setCarreras] = useState<any[]>([]);
  const [sucursales, setSucursales] = useState<any[]>([]);
  const [originalSucursales, setOriginalSucursales] = useState<any[]>([]);
  const [carreras2da, setCarreras2da] = useState<any[]>([]);

  const [carreraSeleccionada, setCarreraSeleccionada] = useState<string>(
    estudios?.carreraSeleccionada ?? '',
  );
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState<string>(
    estudios?.sucursalSeleccionada ?? '',
  );
  const [carrera2daSeleccionada, setCarrera2daSeleccionada] = useState<string>(
    estudios?.carrera2daSeleccionada ?? '',
  );

  useEffect(() => {
    const init = async () => {
      setCarrera2daSeleccionada(estudios?.carrera2daSeleccionada ?? '');
      setSucursalSeleccionada(estudios?.sucursalSeleccionada ?? '');
      if (carreraSeleccionada !== '') {
        const { listaSucursal } = await usjbClient.carrera.obtenerSucursal({
          idCarrera:
            modalidadStored.categoria === categoryResidentado.id
              ? categoryResidentado.idCarrera
              : carreraSeleccionada,
        });
        setOriginalSucursales(listaSucursal);
        setSucursales(
          listaSucursal.map((sucursal) => ({
            text: sucursal.nombreSucursal,
            value: sucursal.idSucursal,
          })),
        );
      }
    };
    init();
  }, [
    carreraSeleccionada,
    estudios?.sucursalSeleccionada,
    estudios?.carrera2daSeleccionada,
  ]);

  useEffect(() => {
    const init = async () => {
      if (sucursalSeleccionada !== '') {
        const { listaCarrera } =
          await usjbClient.carrera.obtenerCarreraSucursal({
            idSucursal: sucursalSeleccionada,
          });

        const carreraSeleccionadaTexto =
          carreras.find((carrera) => carrera.value === carreraSeleccionada)
            ?.text || '';

        let metodoEstudio: string = '';
        if (carreraSeleccionadaTexto.includes('SEMI - PRESENCIAL')) {
          metodoEstudio = 'SEMI - PRESENCIAL';
        } else if (carreraSeleccionadaTexto.includes('A DISTANCIA')) {
          metodoEstudio = 'A DISTANCIA';
        }

        const carrerasDisponibles = listaCarrera.filter(
          ({ idCarrera, nombreCarrera }) => {
            if (
              idCarrera === carreraSeleccionada ||
              nombreCarrera === 'MEDICINA HUMANA'
            ) {
              return false;
            }
            if (metodoEstudio) {
              return nombreCarrera.includes(metodoEstudio);
            }
            return (
              !nombreCarrera.includes('SEMI - PRESENCIAL') &&
              !nombreCarrera.includes('A DISTANCIA')
            );
          },
        );
        carrerasDisponibles.sort((a, b) =>
          a.nombreCarrera.localeCompare(b.nombreCarrera),
        );
        setCarreras2da(
          carrerasDisponibles.map(({ nombreCarrera, idCarrera }) => ({
            text: nombreCarrera,
            value: idCarrera,
          })),
        );
      } else {
        setCarreras2da([]);
      }
    };
    init();
  }, [sucursalSeleccionada, carreraSeleccionada]);
  useEffect(() => {
    const init = async () => {
      const { listaCarrera } = await usjbClient.carrera.obtenerCarrera({
        idCategoria: modalidadStored.categoria,
      });
      listaCarrera.sort((a, b) =>
        a.nombreCarrera.localeCompare(b.nombreCarrera),
      );
      setCarreras(
        listaCarrera.map(({ nombreCarrera, idCarrera }) => ({
          text: nombreCarrera,
          value: idCarrera,
        })),
      );
    };
    init();
  }, []);
  return (
    <div className="flex justify-center mb-5">
      <div className="w-10/12 mx-auto">
        <h3 className="text-2xl col-span-2 text-left my-6-per text-rose-500 text-gray-400 text-lg">
          Elige la opción que prefieras:
        </h3>

        <form className="my-20 mb-10 grid grid-cols-2 gap-6 items-center">
          {false && (
            <h1 className="text-3xl col-span-2 text-center my-6 text-rose-500">
              ME INTERESA ESTUDIAR
            </h1>
          )}
          <Select
            required={true}
            className="col-span-2 text-gray-600"
            label={
              modalidadStored.categoria === categoryResidentado.id
                ? 'Programa'
                : 'Programa Primera Opción'
            }
            options={carreras}
            onChange={(event: any) => {
              setCarreraSeleccionada(event.target.value);
            }}
            value={carreraSeleccionada}
          />
          <Select
            required={true}
            className="col-span-2 text-gray-600"
            label="Local de interés"
            options={sucursales}
            value={sucursalSeleccionada}
            onChange={(event: any) =>
              setSucursalSeleccionada(event.target.value)
            }
          />
          {modalidadStored.categoria !== categoryResidentado.id && (
            <Select
              className="col-span-2 text-gray-600"
              label="Programa Segunda Opción"
              extralabel="Si no obtienes vacante en tu primera opción, se activará automáticamente la segunda opción, en caso la hayas seleccionado."
              value={carrera2daSeleccionada}
              options={carreras2da}
              onChange={(event: any) =>
                setCarrera2daSeleccionada(event.target.value)
              }
            />
          )}
        </form>

        <div>
          <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md">
            <p className="text-red-600">Nota:</p>
            <p className="nota text-gray-600">
              <strong className="upsjb-color-red">*</strong> Campos
              obligatorios.
            </p>
          </div>
        </div>

        <div className="flex justify-center gap-x-6">
          <Button
            hoverbackgroundcolor="upsjb-turquoise-hovering"
            type="button"
            onClick={() => {
              navigate('/pregrado');
            }}
            className="flex items-center"
          >
            <ArrowCircleLeftIcon
              className="text-white rounded-full h-8 w-8 hover:shadow mr-4"
              aria-hidden="true"
            />
            Regresar
          </Button>

          <ContinueButton
            onClick={() => {
              if (carreraSeleccionada !== '' && sucursalSeleccionada !== '') {
                storageManager.set(ESTUDIOS_KEY, {
                  carreraSeleccionada,
                  carreraSeleccionadaLocalCarrera: String(
                    originalSucursales.find(
                      (sucursal) =>
                        sucursal.idSucursal === sucursalSeleccionada,
                    ).idLocalesCarrera,
                  ),
                  carreraSeleccionadaText: carreras.find(
                    (carrera) => carrera.value === carreraSeleccionada,
                  ).text,
                  sucursalSeleccionada,
                  sucursalSeleccionadaText: sucursales.find(
                    (sucursal) => sucursal.value === sucursalSeleccionada,
                  ).text,
                  carrera2daSeleccionada,
                  carrera2daSeleccionadaText: carreras2da.find(
                    (carrera) => carrera.value === carrera2daSeleccionada,
                  )?.text,
                });
                navigate('/modalidades-pregrado?paso=modalidad');
              } else {
                addToast('error', 'Tiene que seleccionar carrera y sucursal');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Estudios;
