import { StepProps } from '../atoms/Step/Step';

export const PASOS: StepProps[] = [
	{
		code: 'carrera',
		done: true,
		alt: 'Programa de estudios',
		src: 'img/estudios.png',
		active: false,
		norder: 1,
	},
	{
		code: 'modalidad',
		done: false,
		alt: 'Modalidad de admisión',
		src: 'img/modalidad.png',
		active: false,
		norder: 2
	},
	{
		code: 'estudios',
		done: false,
		alt: 'Estudios y requisitos',
		src: 'img/datos-personales.png',
		active: false,
		norder: 3
	},
	{
		code: 'pago',
		done: false,
		alt: 'Confirmación de registro',
		src: 'img/pago.png',
		active: false,
		norder: 4
	},
];
