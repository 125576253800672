import { Outlet } from 'react-router-dom';
import { Stepper } from '../Stepper/Stepper';
import GlobalHeader from '../GlobalHeader/GlobalHeader';
import { StepperBanner } from '../StepperBanner/StepperBanner';

const StepperWrapper = () => {
  return (
    <>
      <GlobalHeader />
      <div className="flex justify-center  pt-10">
        <div className="md:w-1/12">

        </div>
        <div className="hidden sm:hidden md:block w-4/12">
          <StepperBanner />
        </div>
        <div className="md:w-6/12">

          <Stepper />
          <div className="wrapper-body mt-2 mb-4 mx-4 my-4">
            <Outlet />
          </div>
        </div>
        <div className="md:w-1/12">

        </div>
      </div>
    </>
  );
};

export default StepperWrapper;