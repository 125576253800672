import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { FC } from 'react';

export interface StepProps {
  code: string;
  done: boolean;
  src: string;
  alt: string;
  active: boolean;
  norder: number;
}

export const Step: FC<StepProps> = (props: StepProps) => {
  return (
    <div className="text-center">
      <div
        className="flex justify-center w-16 mx-auto mb-2"
        title={props.alt}
      >
        {props.done ? (
          <>
            <div
              className="mx-auto flex h-12 w-12 items-center justify-center rounded-full upsjb-red "
            >
              <CheckIcon
                className="h-6 w-6 text-white	"
                aria-hidden="true"
              />
            </div>
            {false &&
              <div className="w-12 h-12 rounded-full flex justify-center items-center">
                <p className="text-white font-bold text-3xl font-agenda-medium">✔</p>
              </div>}
          </>
        ) : (
          props.active ? (
            <>
              <div className="w-12 h-12 rounded-full upsjb-red flex justify-center items-center">
                <p className="text-white font-bold text-3xl">{props.norder}</p>
              </div>
            </>
          ) : (<>
            <div className="w-12 h-12 rounded-full bg-zinc-300 flex justify-center items-center">
              <p className="text-white font-bold text-3xl"> </p>
            </div>
          </>)
        )}
      </div>
      <h1
        className={classNames(
          'text-sm md:text-md xl:text-xl 2xl:text-xl font-agenda-medium',
          'w-24 md:w-30 xl:w-40 2xl:w-40 md:block',
          { 'text-rose-600': props.active || props.done, 'text-zinc-300': !(props.active || props.done) },
        )}
      >
        {props.alt}
      </h1>
    </div>
  );
};
