import axios from 'axios';
import {
  connectionErrorDescription,
  connectionErrorTitle,
  requestErrorDescription,
  requestErrorTitle,
} from './utils/strings';
import { connectionErrorDialog } from './utils/dialogs';
import { ModalidadCategoria } from './models/ModalidadCategoria';
import { Modalidad } from './models/Modalidad';
import { RequisitoDAO } from './models/Requisito';
import { ModalidadTurno } from './models/ModalidadTurno';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api-admision`,
  timeout: 50000,
  headers: { 'content-type': 'application/json' },
});

const instancePSIGW = axios.create({
  baseURL: process.env.REACT_APP_PSIGW_BACKEND_URL,
  timeout: 50000,
  headers: { 'content-type': 'application/json' },
});

// Interceptor para manejar errores de solicitud HTTP
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('Error interceptado:', error);

    if (error.response) {
      // Error en la respuesta del servidor
      const { status } = error.response;

      if (status === 401) {
        // Maneja el código 401 (No autorizado) aquí si es necesario
      } else {
        // Mostrar un mensaje de error utilizando SweetAlert2
        connectionErrorDialog(connectionErrorTitle, connectionErrorDescription);
      }
    } else if (error.request) {
      connectionErrorDialog(requestErrorTitle, connectionErrorDescription);
    } else {
      // Error en la configuración de la solicitud
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
    }

    return Promise.reject(error);
  },
);

const instance_integracion = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 50000,
  headers: {
    'content-type': 'application/json',
    country: 'PE',
    provider: 'integracion',
    apiKey: process.env.REACT_APP_BACKEND_APIKEY ?? '', // "Z2ZKVS8vMk15SVg5M0Fqdlc5MFY1R2s2alJBbE01Sk9PZWhaV1ovbkhXU2VIRmQ4T0w2UU5wUHZKeWZ5bkg0dA=="
  },
});
// const instance_integracion = axios.create({
//   baseURL: "https://app.upsjb.edu.pe",//process.env.REACT_APP_BACKEND_URL,
//   timeout: 50000,
//   headers: {
//     "content-type": "application/json",
//     "country": "PE",
//     "provider": "integracion",
//     "apiKey": "UTdFZStJaWd6ekJ6eW5OQ2dOOU83ZEluTWpheXlEWklrQk0rekN2WWlGRT0="
//   },
// });
export const usjbClient = (): {
  validacion: {
    verificarSms: (input: {
      pin: string;
      pinId: string;
      recaptcha: string;
    }) => Promise<{ mensajeStatus: string }>;
    verificarEmail: (input: {
      pin: string;
      pinId: string;
      recaptcha: string;
    }) => Promise<{ mensajeStatus: string }>;
    enviarSms: (input: {
      contacto: string;
      documentoPostulante: string;
      tipoDocumento: string;
      nombrePostulante: string;
      apellidoPostulante: string;
      recaptcha: string;
    }) => Promise<{ mensajeStatus: string; pinId: string }>;
    enviarEmail: (input: {
      contacto: string;
      documentoPostulante: string;
      tipoDocumento: string;
      nombrePostulante: string;
      apellidoPostulante: string;
      recaptcha: string;
    }) => Promise<{ mensajeStatus: string; pinId: string }>;
  };
  postulante: {
    registrarPostulante: (input: {
      postulante: {
        turno: string;
        turnoSdo: string;
        tipoDocumento: string;
        documentoPostulante: string;
        nombre: string;
        apellidoPaterno: string;
        apellidoMaterno: string;
        genero: string;
        idEstadoCivil: string;
        idColegio: string;
        ubigeoDomicilio: string;
        fechaNacimiento: string;
        paisNacimiento: string;
        ubigeoNacimiento: string;
        carreraPrimeraOpcion: string;
        local: string;
        annioTermino: string;
        carreraSegundaOpcion: string;
        correo: string;
        celular: string;
        idVia: string;
        domicilioVia: string;
        idTipoZona: string;
        domicilioZona: string;
        direccion: string;
        referencia: string;
        discapacidad: boolean;
        discapacidadDescripcion: string;
      };
      idProceso: number;
      requisitos: {
        idRequisito: number;
        base64: string;
        contentType: string;
      }[];
    }) => Promise<{
      resultado: string;
      solicitud: string;
      emplId: string;
      importe: string; //,
      //  codigo: string
    }>;
    generarDeuda: (input: { emplid: string; modalidadId: string }) => Promise<{
      emplId: string;
      nombre: string;
      detalleDeuda: {
        item: string;
        descripcion: string;
        fechaVencimiento: string;
        importeDeuda: string;
        tipoItem: string;
        anioAcad: string;
        numeroCuenta: string;
      }[];
    }>;
    generarRecibo: (input: {
      idImpl: string;
      email: string;
      nroItem: string;
      tipoItem: string;
      importePagar: string;
      nroCuenta: string;
      anhoAcademico: string;
    }) => Promise<{
      codigoRespuesta: number;
      mensajeRespuesta: string;
      fechaHoraRespuesta: string;
      idAlumno: string;
      nroRecibo: string;
      totalPagar: number;
      codigoComercio: string;
      tokenSeguridad: string;
      sessionKey: string;
      eticket: string;
      urlJavaScriptBoton: string;
      urlRespuestaAPIRest: string;
    }>;
    registrarRecibo: (input: {
      tokenSeguridad: string;
      eticket: string;
      transactionToken: string;
      codigoComercio: string;
      nroRecibo: string;
      impTotalPagar: string;
    }) => Promise<{
      codigoRespuesta: number;
      mensajeRespuesta: string;
      fechaHoraRespuesta: string;
      nroRecibo: string;
    }>;
    obtenerEmplId: (input: { numDoc: string }) => Promise<{
      status_code: string;
      status: string;
      description: string;
      result: {
        codigoalumno: string;
        nombrealumno: string;
      };
    }>;
    consultarPostulacion: (input: { emplId: string }) => Promise<{
      status_code: string;
      status: string;
      description: string;
      result: {
        emplId: string;
        nombre: string;
        grado: string;
        ciclo: string;
      }[];
    }>;
    validacionPostulacion: (input: {
      dni: string;
      proceso: string;
      semestre: string;
    }) => Promise<{
      validacion: string;
    }>;
    validacionPostulacionEstudianteExistente: (input: {
      tipoDocumento: string;
      numeroDocumento: string;
    }) => Promise<{
      SJB_ESTU_ADMIN_ACT_RESP: any;
    }>;
    validacionDeudaExistente: (input: {
      tipoDocumento: string;
      numeroDocumento: string;
    }) => Promise<{
      SJB_VAL_DEU_DNI_RESP: any;
    }>;
  };
  persona: {
    registrarPersona: (input: {
      persona: {
        tipoDocumento: number;
        numeroDocumento: string;
        digitoVerificacion: string;
        apellidoPaterno: string;
        apellidoMaterno: string;
        nombres: string;
        paisNacionalidadId: number;
        ubigeoNacimiento: string;
        sexoId: number;
        fechaNacimiento: string;
        estadoCivilId: number;
        gradoInstruccionId: number;
        estatura: number;
        nombreMadre: string;
        fechaInscripcion: string;
        ubigeoDomicilio: string;
        tipoViaId: number;
        domicilioVia: string;
        tipoZonaId: number;
        domicilioZona: string;
        domicilioReferencia: string;
        telefono: string;
        celular: string;
        emailPersonal: string;
        fechaExpedicion: string;
        restriccionId: number;
        fechaCaducidad: string;
        fechaFallecimiento: string;
        fotoId: number;
        personaId: number;
        nombrePadre: string;
      };
    }) => Promise<{ idPersona: string; estado: string }>;
  };
  confirmacion: {
    confirmacionEnvio: (input: {
      tipoDocumento: string;
      documentoPostulante: string;
      correo: string;
      nombre: string;
      apellidoPaterno: string;
      apellidoMaterno: string;
      carreraPrimeraOpcion: string;
      local: string;
      carreraSegundaOpcion: string;
    }) => Promise<{ cuerpo: string }>;
  };
  archivo: {
    cargarArchivo: (input: {
      idProceso: number;
      nombreArchivo: string;
      idRequisito: number;
      archivo: { base64: string; contentType: string };
      dniPostulante: string;
    }) => Promise<{ estadoArchivo: string }>;
  };
  ubigeo: {
    obtenerUbigeo: () => Promise<{
      listaUbigeo: { valor: string; ubigeo: string }[];
    }>;
  };
  terminos: {
    obtenerTerminosYCondiciones: () => Promise<{ cuerpo: string }>;
  };
  redis: {
    obtenerViaByNewService: () => Promise<{ valor: string; texto: string }[]>;
    obtenerTurnoByNewService: () => Promise<{ valor: string; texto: string }[]>;
    obtenerTipoZonaByNewService: () => Promise<
      { valor: string; texto: string }[]
    >;
    obtenerTipoDocumentoRedis: () => Promise<
      { valor: string; texto: string }[]
    >;
    obtenerProcesosByNewService: () => Promise<
      { valor: string; texto: string }[]
    >;
    obtenerPaisRedis: () => Promise<{ valor: string; texto: string }[]>;
    obtenerGeneroByNewService: () => Promise<
      { valor: string; texto: string }[]
    >;
    obtenerEstadoCivilByNewService: () => Promise<
      { valor: string; texto: string }[]
    >;
  };
  pronabec: {
    obtenerPronabec: (input: {
      dni: string;
    }) => Promise<{ esPronabec: boolean }>;
  };
  parametro: {
    obtenerVia: () => Promise<{ valor: string; texto: string }[]>;
    obtenerTurno: () => Promise<{
      listaParametro: { valor: string; texto: string }[];
    }>;
    obtenerTipoZona: () => Promise<{ valor: string; texto: string }[]>;
    obtenerTipoDocumento: () => Promise<{
      listaParametro: { valor: string; texto: string }[];
    }>;
    obtenerProcesos: () => Promise<{ valor: string; texto: string }[]>;
    obtenerPais: () => Promise<{
      listaParametro: { valor: string; texto: string }[];
    }>;
    obtenerGenero: () => Promise<{
      listaParametro: { valor: string; texto: string }[];
    }>;
    obtenerDiscapacidad: () => Promise<{
      listaParametro: { valor: string; texto: string }[];
    }>;
    obtenerEstadoCivil: () => Promise<{ valor: string; texto: string }[]>;
  };
  monto: {
    obtenerToken: () => Promise<{ token: string }>;
    obtenerPrecio: () => Promise<{ monto: number }>;
  };
  modalidad: {
    obtenerListarRequisito: (input: { idModalidad: string }) => Promise<{
      listRequisito: RequisitoDAO[];
    }>;
    obtenerTurnos: (input: {
      grado: string;
      idCarrera: string;
      idSucursal: string;
      idSemestre: string;
    }) => Promise<ModalidadTurno[]>;
    obtenerListarModalidadByCategoria: (input: {
      idCategoria: string;
    }) => Promise<{
      listModalidad: Modalidad[];
    }>;
    obtenerListarCategoriaModalidad: () => Promise<{
      listaModalidad: ModalidadCategoria[];
    }>;
  };
  colegio: {
    obtenerColegioPorUbigeo: (input: { valor: string }) => Promise<{
      listaColegio: {
        ColegioId: number;
        CodigoTipoColegio: string;
        CodigoNivelColegio: string;
        UbigeoDireccion: string;
        CategoriaPago: string;
        CodigoPais: string;
        Direccion: string;
        NombreColegio: string;
        DescripcionTipoColegio: string;
        FlgConvenio: number;
        NombreColegioDireccion: string;
        colegioId: number;
        codigoTipoColegio: string;
        codigoNivelColegio: string;
        ubigeoDireccion: string;
        categoriaPago: string;
        codigoPais: string;
        nombreColegio: string;
        descripcionTipoColegio: string;
        flgConvenio: number;
        nombreColegioDireccion: string;
        direccion: string;
      }[];
    }>;
  };
  carrera: {
    obtenerCarrera: (input: { idCategoria: string }) => Promise<{
      listaCarrera: { idCarrera: string; nombreCarrera: string }[];
    }>;
    obtenerSucursal: (input: { idCarrera: string }) => Promise<{
      listaSucursal: {
        idSucursal: string;
        nombreSucursal: string;
        imagenSucursal: string;
        idLocalesCarrera: number;
      }[];
    }>;
    obtenerCarreraSucursal: (input: { idSucursal: string }) => Promise<{
      listaCarrera: { idCarrera: string; nombreCarrera: string }[];
    }>;
  };
} => {
  return {
    validacion: {
      verificarSms: async ({
        pin,
        pinId,
        recaptcha,
      }: {
        pin: string;
        pinId: string;
        recaptcha: string;
      }) => {
        const { data } = await instance.post(`/validacion/verificarSms`, {
          pin,
          pinId,
          recaptcha,
        });
        return data;
      },
      verificarEmail: async ({
        pin,
        pinId,
        recaptcha,
      }: {
        pin: string;
        pinId: string;
        recaptcha: string;
      }) => {
        const { data } = await instance.post(`/validacion/verificarEmail`, {
          pin,
          pinId,
          recaptcha,
        });
        return data;
      },
      enviarSms: async ({
        contacto,
        documentoPostulante,
        tipoDocumento,
        nombrePostulante,
        apellidoPostulante,
        recaptcha,
      }: {
        contacto: string;
        documentoPostulante: string;
        tipoDocumento: string;
        nombrePostulante: string;
        apellidoPostulante: string;
        recaptcha: string;
      }) => {
        const { data } = await instance.post(`/validacion/enviarSms`, {
          contacto,
          documentoPostulante,
          tipoDocumento,
          nombrePostulante,
          apellidoPostulante,
          recaptcha,
        });
        return data;
      },
      enviarEmail: async ({
        contacto,
        documentoPostulante,
        tipoDocumento,
        nombrePostulante,
        apellidoPostulante,
        recaptcha,
      }: {
        contacto: string;
        documentoPostulante: string;
        tipoDocumento: string;
        nombrePostulante: string;
        apellidoPostulante: string;
        recaptcha: string;
      }) => {
        const { data } = await instance.post(`/validacion/enviarEmail`, {
          contacto,
          documentoPostulante,
          tipoDocumento,
          nombrePostulante,
          apellidoPostulante,
          recaptcha,
        });
        return data;
      },
    },
    postulante: {
      registrarPostulante: async ({
        postulante,
        idProceso,
        requisitos,
      }: //recaptcha,
      {
        postulante: {
          turno: string;
          turnoSdo: string;
          tipoDocumento: string;
          documentoPostulante: string;
          nombre: string;
          apellidoPaterno: string;
          apellidoMaterno: string;
          genero: string;
          idEstadoCivil: string;
          idColegio: string;
          ubigeoDomicilio: string;
          fechaNacimiento: string;
          paisNacimiento: string;
          ubigeoNacimiento: string;
          carreraPrimeraOpcion: string;
          local: string;
          annioTermino: string;
          carreraSegundaOpcion: string;
          correo: string;
          celular: string;
          idVia: string;
          domicilioVia: string;
          idTipoZona: string;
          domicilioZona: string;
          direccion: string;
          referencia: string;
          discapacidad: boolean;
          discapacidadDescripcion: string;
        };
        idProceso: number;
        requisitos: {
          idRequisito: number;
          base64: string;
          contentType: string;
        }[];
        //recaptcha: string;
      }) => {
        const { data } = await instance.post(`/postulante/registrar`, {
          postulante,
          idProceso,
          requisitos,
          //recaptcha,
        });
        return data;
      },
      generarDeuda: async ({
        emplid,
        modalidadId,
      }: {
        emplid: string;
        modalidadId: string;
      }) => {
        const { data } = await instance.post(`/postulante/obtenerDeuda`, {
          emplid,
          idModalidad: modalidadId,
        });
        return data;
      },
      generarRecibo: async ({
        idImpl,
        email,
        nroItem,
        tipoItem,
        importePagar,
        nroCuenta,
        anhoAcademico,
      }: {
        idImpl: string;
        email: string;
        nroItem: string;
        tipoItem: string;
        importePagar: string;
        nroCuenta: string;
        anhoAcademico: string;
      }) => {
        const { data } = await instance.post('/postulante/obtenerRecibo', {
          idImpl,
          email,
          nroItem,
          tipoItem,
          importePagar,
          nroCuenta,
          anhoAcademico,
        });
        return data;
      },
      registrarRecibo: async ({
        tokenSeguridad,
        eticket,
        transactionToken,
        codigoComercio,
        nroRecibo,
        impTotalPagar,
      }: {
        tokenSeguridad: string;
        eticket: string;
        transactionToken: string;
        codigoComercio: string;
        nroRecibo: string;
        impTotalPagar: string;
      }) => {
        const { data } = await instance.post('/postulante/registrarRecibo', {
          tokenSeguridad,
          eticket,
          transactionToken,
          codigoComercio,
          nroRecibo,
          impTotalPagar,
        });
        return data;
      },
      obtenerEmplId: async ({ numDoc }: { numDoc: string }) => {
        const { data } = await instance_integracion.get(
          '/apisIntegracionesBancos/api/v1/integracion/banco/obtener-deuda-documento?numDoc=' +
            numDoc,
        );
        return data;
      },
      consultarPostulacion: async ({ emplId }: { emplId: string }) => {
        const { data } = await instance_integracion.post(
          '/apisIntegracionesAdmision/api/v1/integracion/admision/obtener-postulante-campus',
          {
            emplId,
          },
        );
        return data;
      },
      validacionPostulacion: async ({
        dni,
        proceso,
        semestre,
      }: {
        dni: string;
        proceso: string;
        semestre: string;
      }) => {
        const { data } = await instance.post('/postulante/validarPostulacion', {
          dni,
          proceso,
          semestre,
        });
        return data;
      },
      validacionPostulacionEstudianteExistente: async ({
        tipoDocumento,
        numeroDocumento,
      }: {
        tipoDocumento: string;
        numeroDocumento: string;
      }) => {
        const { data } = await instancePSIGW.get(
          `/SJB_ESTU_ADM_ACT_OPE_SERV.v1/Param/${tipoDocumento}/${numeroDocumento}`,
        );
        return data;
      },
      validacionDeudaExistente: async ({
        tipoDocumento,
        numeroDocumento,
      }: {
        tipoDocumento: string;
        numeroDocumento: string;
      }) => {
        const { data } = await instancePSIGW.get(
          `SJB_VAL_DEU_DNI_OPE_SERV.v1/Param/${tipoDocumento}/${numeroDocumento}/SF001`,
        );
        return data;
      },
    },
    persona: {
      registrarPersona: async ({
        persona,
      }: {
        persona: {
          tipoDocumento: number;
          numeroDocumento: string;
          digitoVerificacion: string;
          apellidoPaterno: string;
          apellidoMaterno: string;
          nombres: string;
          paisNacionalidadId: number;
          ubigeoNacimiento: string;
          sexoId: number;
          fechaNacimiento: string;
          estadoCivilId: number;
          gradoInstruccionId: number;
          estatura: number;
          nombreMadre: string;
          fechaInscripcion: string;
          ubigeoDomicilio: string;
          tipoViaId: number;
          domicilioVia: string;
          tipoZonaId: number;
          domicilioZona: string;
          domicilioReferencia: string;
          telefono: string;
          celular: string;
          emailPersonal: string;
          fechaExpedicion: string;
          restriccionId: number;
          fechaCaducidad: string;
          fechaFallecimiento: string;
          fotoId: number;
          personaId: number;
          nombrePadre: string;
        };
      }) => {
        const { data } = await instance.post(`/persona/registrar`, { persona });
        return data;
      },
    },
    confirmacion: {
      confirmacionEnvio: async ({
        tipoDocumento,
        documentoPostulante,
        correo,
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        carreraPrimeraOpcion,
        local,
        carreraSegundaOpcion,
      }: {
        tipoDocumento: string;
        documentoPostulante: string;
        correo: string;
        nombre: string;
        apellidoPaterno: string;
        apellidoMaterno: string;
        carreraPrimeraOpcion: string;
        local: string;
        carreraSegundaOpcion: string;
      }) => {
        const { data } = await instance.post(`/confirmacion`, {
          tipoDocumento,
          documentoPostulante,
          correo,
          nombre,
          apellidoPaterno,
          apellidoMaterno,
          carreraPrimeraOpcion,
          local,
          carreraSegundaOpcion,
        });
        return data;
      },
    },
    archivo: {
      cargarArchivo: async ({
        idProceso,
        nombreArchivo,
        idRequisito,
        archivo,
        dniPostulante,
      }: {
        idProceso: number;
        nombreArchivo: string;
        idRequisito: number;
        archivo: { base64: string; contentType: string };
        dniPostulante: string;
      }) => {
        const { data } = await instance.post(`/archivo/cargar`, {
          idProceso,
          nombreArchivo,
          idRequisito,
          archivo,
          dniPostulante,
        });
        return data;
      },
    },
    ubigeo: {
      obtenerUbigeo: async () => {
        const { data } = await instance.get(`/ubigeo/lista`);
        return data;
      },
    },
    terminos: {
      obtenerTerminosYCondiciones: async () => {
        const { data } = await instance.get(`/terminos/obtener`);
        return data;
      },
    },
    redis: {
      obtenerViaByNewService: async () => {
        const { data } = await instance.get(`/redis/parametro/via`);
        return data;
      },
      obtenerTurnoByNewService: async () => {
        const { data } = await instance.get(`/redis/parametro/turno`);
        return data;
      },
      obtenerTipoZonaByNewService: async () => {
        const { data } = await instance.get(`/redis/parametro/tipoZona`);
        return data;
      },
      obtenerTipoDocumentoRedis: async () => {
        const { data } = await instance.get(`/redis/parametro/tipoDocumento`);
        return data;
      },
      obtenerProcesosByNewService: async () => {
        const { data } = await instance.get(`/redis/parametro/procesos`);
        return data;
      },
      obtenerPaisRedis: async () => {
        const { data } = await instance.get(`/redis/parametro/pais`);
        return data;
      },
      obtenerGeneroByNewService: async () => {
        const { data } = await instance.get(`/redis/parametro/genero`);
        return data;
      },
      obtenerEstadoCivilByNewService: async () => {
        const { data } = await instance.get(`/redis/parametro/estadoCivil`);
        return data;
      },
    },
    pronabec: {
      obtenerPronabec: async ({ dni }: { dni: string }) => {
        const { data } = await instance.get(
          `/pronabec/esBecario/${encodeURIComponent(dni)}`,
        );
        return data;
      },
    },
    parametro: {
      obtenerVia: async () => {
        const { data } = await instance.get(`/parametro/via`);
        return data;
      },
      obtenerTurno: async () => {
        const { data } = await instance.get(`/parametro/turno`);
        return data;
      },
      obtenerTipoZona: async () => {
        const { data } = await instance.get(`/parametro/tipoZona`);
        return data;
      },
      obtenerTipoDocumento: async () => {
        const { data } = await instance.get(`/parametro/tipoDocumento`);
        return data;
      },
      obtenerProcesos: async () => {
        const { data } = await instance.get(`/parametro/procesos`);
        return data;
      },
      obtenerPais: async () => {
        const { data } = await instance.get(`/parametro/pais`);
        return data;
      },
      obtenerGenero: async () => {
        const { data } = await instance.get(`/parametro/genero`);
        return data;
      },
      obtenerEstadoCivil: async () => {
        const { data } = await instance.get(`/parametro/estadoCivil`);
        return data;
      },
      obtenerDiscapacidad: async () => {
        const { data } = await instance.get(`/parametro/discapacidad`);
        return data;
      },
    },
    monto: {
      obtenerToken: async () => {
        const { data } = await instance.get(`/monto/token`);
        return data;
      },
      obtenerPrecio: async () => {
        const { data } = await instance.get(`/monto/precio`);
        return data;
      },
    },
    modalidad: {
      obtenerListarRequisito: async ({
        idModalidad,
      }: {
        idModalidad: string;
      }) => {
        const { data } = await instance.get(
          `/modalidad/obtenerRequisito/${encodeURIComponent(idModalidad)}`,
        );
        return data;
      },
      obtenerTurnos: async ({
        grado,
        idCarrera,
        idSucursal,
        idSemestre,
      }: {
        grado: string;
        idCarrera: string;
        idSucursal: string;
        idSemestre: string;
      }) => {
        const { data } = await instancePSIGW.get(
          `/SJB_TURNOS_OPE.v1/Param/UPSJB/${encodeURIComponent(
            grado,
          )}/${encodeURIComponent(idCarrera)}/${encodeURIComponent(
            idSucursal,
          )}/${encodeURIComponent(idSemestre)}`,
        );
        return data.SJB_OBTIENE_TURNOS_RESP?.SJB_OBTIENE_TURNOS_RES;
      },
      obtenerListarModalidadByCategoria: async ({
        idCategoria,
      }: {
        idCategoria: string;
      }) => {
        const { data } = await instance.get(
          `/modalidad/obtenerModalidad/${encodeURIComponent(idCategoria)}`,
        );
        return data;
      },
      obtenerListarCategoriaModalidad: async () => {
        const { data } = await instance.get(`/modalidad/categoria`);
        return data;
      },
    },
    colegio: {
      obtenerColegioPorUbigeo: async ({ valor }: { valor: string }) => {
        const { data } = await instance.get(
          `/colegio/obtenerPorUbigeo/${encodeURIComponent(valor)}`,
        );
        return data;
      },
    },
    carrera: {
      obtenerCarrera: async ({ idCategoria }: { idCategoria: string }) => {
        const { data } = await instance.get(`/carrera/obtener/${idCategoria}`);
        return data;
      },
      obtenerSucursal: async ({ idCarrera }: { idCarrera: string }) => {
        const { data } = await instance.get(
          `/carrera/obtenerSucursal/${encodeURIComponent(idCarrera)}`,
        );
        return data;
      },
      obtenerCarreraSucursal: async ({
        idSucursal,
      }: {
        idSucursal: string;
      }) => {
        const { data } = await instance.get(
          `/carrera/obtenerCarrera/${encodeURIComponent(idSucursal)}`,
        );
        return data;
      },
    },
  };
};
export default usjbClient();
