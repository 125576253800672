import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import { Select } from '../../atoms/Select/Select';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { TIPO_DOCUMENTOS } from '../../constants/tipoDocumentos';

const InicioSesion = () => {
  const [validated, setValidated] = useState(false);
  return (
    <div className="flex justify-center">
      <form className="md:w-1/2 grid grid-cols-2 gap-6">
        <h1 className="text-3xl col-span-2 text-center my-6 text-rose-500">
          CONSULTAR INSCRIPCIÓN
        </h1>
        <Select
          className="md:col-span-1 col-span-2"
          options={TIPO_DOCUMENTOS}
          label="Tipo de documento de identidad*"
        />
        <TextInput
          className="md:col-span-1 col-span-2"
          label="Número de documento de identidad"
          placeholder="88888888"
        />
        <TextInput
          onChange={() => setValidated(false)}
          className="col-span-1"
          label="Número de celular"
          placeholder="999999999"
        />
        <Button
          onClick={() => setValidated(true)}
          className="col-span-1"
          type="button"
        >
          Validar
        </Button>
        {validated ? (
          <>
            <TextInput
              disabled={!validated}
              className="col-span-2"
              label="Código de verificación"
            />
            <div className="flex justify-center col-span-2">
              <Link to="/info-inscripcion">
                <Button type="button">Iniciar sesión</Button>
              </Link>
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default InicioSesion;
