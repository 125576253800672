export class ModalidadCategoria {
    id: string;
    nombre: string;
    descripcion: string;
    idProceso: string;
    constructor(data: any) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.descripcion = data.descripcion;
        this.idProceso = data.idProceso;
    }
    static fromJSON(data: any) {
        return new ModalidadCategoria(data);
    }
    public get identify(): string {
        if (this.id && this.id.length > 2) {
            return this.id.substring(1, 3);
        } else {
            return this.id;
        }
    }
    public get color(): string {
        if (this.id === "001") {
            return "bloquemodop1"
        } else if ( this.id === "002" ) {
            return "bloquemodop4";
        }
        return "";
    }
}
