import {
  forwardRef,
  Fragment,
  HTMLProps,
  useEffect,
  useState,
} from 'react';
import '../Input.css';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import MiniSearch from 'minisearch';
import { FieldError } from 'react-hook-form';
import debounce from 'lodash/debounce';
import Tooltip from "../../molecules/Tooltip/Tooltip";
export interface Option {
  text: string;
  value: string;
}

export interface AutocompleteProps {
  options?: Option[];
  label?: string;
  toolTip?: string;
  error?: FieldError;
  width?: string;
  required?: boolean;
  defaultSelected?: any;
  onSelect?: (selectedItem: Option) => void; // Nuevo prop para manejar la selección
}

export const Autocomplete = forwardRef<
  HTMLInputElement,
  AutocompleteProps & HTMLProps<HTMLInputElement>
>(
  (
    { options, ...props }: AutocompleteProps & HTMLProps<HTMLInputElement>,
    ref,
  ) => {
    const [selected, setSelected] = useState<string>();
    const [query, setQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState<any[]>([]);
    const [minisearch, setminiSearch] = useState<MiniSearch | null>(null);

    const onItemIsSelected = (payload: any) => {
      props.onChange?.({
        target: { name: props.name, value: payload.id },
      } as any);
      setSelected(payload);
      props.onSelect?.(payload);
    }

    useEffect(() => {
      setSelected(props.defaultSelected);
      onItemIsSelected(props.defaultSelected);
    }, [props.defaultSelected]);

    useEffect(() => {
      const minisearch = new MiniSearch({
        fields: ['text'],
        storeFields: ['text', 'value'],
        idField: 'value',
        searchOptions: {
          fuzzy: 0.5,
        },
      });
      minisearch.addAll(options ?? []);
      setminiSearch(minisearch);
    }, [options]);
    useEffect(() => {
      if (minisearch) {
        if ( query ) {
          setSelected(undefined);
          setFilteredResults(minisearch.search(query, { prefix: true }));
        } else {
        }
      }
    }, [query, minisearch]);
    const onChangeDebounced = debounce((event: any) => {
      setQuery(event.target.value);
    }, 200);
    return (
      <div className={props.className}>
        <Combobox
          value={selected}
          onChange={(payload: any) => {
            onItemIsSelected(payload);
          }}
        >
          <div className="relative mt-1">
            <div className="flex">
              <Combobox.Label>
                {props.label}
                {props.required && <strong className='upsjb-color-red'>*</strong>}
              </Combobox.Label>
              {props.toolTip !== undefined ? <Tooltip>
                {props.toolTip}
              </Tooltip> : ''}
            </div>
            <Combobox.Input
              autoComplete='off'
              placeholder={props.placeholder}
              ref={ref}
              className="combobox w-full border-none py-2 pl-3 pr-10 text-sm leading-5 rounded-lg shadow-lg text-gray-900 focus:ring-0 focus:outline-none"
              displayValue={(option: any) => {
                return option?.text || query;
              }}
              onChange={onChangeDebounced}
            />


            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 mt-2">
              <SelectorIcon
                className="h-5 w-5  text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
            <div className="text-sm text-red-500 h-2 mt-2">
              {props.error?.message}
            </div>


          </div>


          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          //afterLeave={() => setQuery("")}
          >
            <Combobox.Options className={`absolute mt-1 max-h-60 w-4/5 sm:w-96 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50`}>
              {filteredResults.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  No se encontraron resultados
                </div>
              ) : (
                filteredResults.map((result: any) => (
                  <Combobox.Option
                    key={result.value}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-rose-500 text-white' : 'text-gray-900'
                      }`
                    }
                    value={result}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {result.text}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                              }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </Combobox>
      </div>
    );
  },
);
Autocomplete.defaultProps = {
  width: 'sm:w-4/5 md:w-96',
  required: false,
  defaultSelected: {}
};