import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';

export interface TooltipProps {
  icon?: ReactNode;
}

const Tooltip: FC<PropsWithChildren<TooltipProps>> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div
      className="relative"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {props.icon ? (
        props.icon
      ) : (
        <QuestionMarkCircleIcon className="h-4 w-4 mx-4 my-auto upsjb-color-turquoise"></QuestionMarkCircleIcon>
      )}
      {show ? (
        <div className="absolute bottom-8 right-1/4 p-4 shadow-xl rounded-lg w-60 md:w-120 z-10 bg-white">
          {props.children}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Tooltip;
