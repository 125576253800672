import './SocialMedia.css';

const SocialMedia = () => {
    return (
        <div className="elementor-social-icons-wrapper elementor-grid">
            <span className="elementor-grid-item">
                <a className="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-animation-shrink elementor-repeater-item-182acc5"
                    href="https://mailing.upsjb.edu.pe/e3t/Ctc/RI+113/cZqXY04/VWLMJc8p5J0dW1jx3X95P4GtQW4Rs6st4MZC0WN6Q02rw3lLB3V1-WJV7CgZYCW8Qq-kr2752MsW7Sp3Rb28lRTjN4fjX8tYWqH_VbXJC85WNXzdVGHJd52B2kT_W6pHgMj3qFgChW2sJmQ72RwZgJW3G6q263-rNgNN468j3s32Ry5W8GNR_G1MCVRcVkjr0d3P2XXfW350Qzx14c7JyW7-Vg9C8FWPVMW6MF0BQ78g0hzW2-Glqz42f6ThN6scsX8GdKN2W91x4WT1W4Rt3N3CLwlLKzmHdW62GRcX140CKvN2nS02VRXBdX3pFF1?utm_campaign=Eventos%20Acad%C3%A9micos&amp;utm_source=hs_email&amp;utm_medium=email&amp;_hsenc=p2ANqtz--pO3FhWqIZofWFkobiMHGdSclg69AhJdYf5dOrbB87hUpNf8fPqQBTe4rL7GgDHUZgfhBo&amp;hsLang=es-pe"
                    target="_blank" rel="noreferrer"
                    aria-label="Whatsapp - abrir en una nueva pestaña">
                    <span className="elementor-screen-only">Whatsapp</span>
                    <svg className="e-font-icon-svg e-fab-whatsapp"
                        viewBox="0 0 448 512"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                    </svg>
                </a>
            </span>
            <span className="elementor-grid-item">
                <a className="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-shrink elementor-repeater-item-f3a4452"
                    href="https://www.messenger.com/t/320664863857/?messaging_source=source%3Apages%3Amessage_shortlink"
                    target="_blank" rel="noreferrer"
                    aria-label="Facebook-f - abrir en una nueva pestaña">
                    <span className="elementor-screen-only">Facebook-f</span>
                    <svg className="e-font-icon-svg e-fab-facebook-f"
                        viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                    </svg>
                </a>
            </span>
            <span className="elementor-grid-item">
                <a className="elementor-icon elementor-social-icon elementor-social-icon-phone-alt elementor-animation-shrink elementor-repeater-item-a8a52dc"
                    href="tel:017095999"
                    target="_blank" rel="noreferrer"
                    aria-label="call 017095999">
                    <span className="elementor-screen-only">Phone-alt</span>
                    <svg className="e-font-icon-svg e-fas-phone-alt"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                    </svg>
                </a>
            </span>
        </div>);
}
export default SocialMedia;