import { CheckIcon } from '@heroicons/react/solid';

export type IconoConfirmadoProps = {
  width?: string;
  height?: string;
}

const IconoConfirmado = ({
  width = 'w-12',
  height = 'h-12'
}: IconoConfirmadoProps) => {
  return (
    <div className={`mx-auto flex ${width} ${height} self-center items-center justify-center rounded-full bg-green-500`}>
      <CheckIcon className="h-8 w-8 text-white" aria-hidden="true" />
    </div>
  );
};



export default IconoConfirmado;
