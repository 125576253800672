import { useEffect, useState, useRef, useCallback } from 'react';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { es } from 'date-fns/esm/locale';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '../../atoms/Autocomplete/Autocomplete';
import Button from '../../atoms/Button/Button';
import { Select } from '../../atoms/Select/Select';
import { TextInput } from '../../atoms/TextInput/TextInput';
import {
  DATOS_PERSONALES_KEY,
  ESTUDIOS_KEY,
  MODALIDAD_KEY,
} from '../../constants/keys';
import ReCAPTCHA from 'react-google-recaptcha';

import { useStorageManager } from '../../hooks/useStateManager';
import { useToast } from '../../hooks/useToast';
import { ContinueButton } from '../../molecules';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import usjbClient from '../../sdk';
import {
  obtenerPaises,
  obtenerTiposDocumento,
  obtenerGeneros,
} from '../../api/mappers/parametro';
import { obtenerUbigeo } from '../../api/mappers/ubigeo';
import {
  buttonColorGray,
  buttonColorPink,
  homePath,
  regexCellphone,
  requestErrorDescription,
  requestErrorTitle,
} from '../../utils/strings';
import {
  DatosPersonales,
  DatosPersonalesExtendidos,
  datosPersonalesValidation,
} from '../../validations/datos-personales.validation';
import { MAX_FECHA_NACIMIENTO } from '../../validations/fecha-nacimiento.validation';
import './date-picker.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DatosEstudios } from '../../models/DatosEstudios';
import { connectionErrorDialog, endMessageDialog } from '../../utils/dialogs';
import Loading from '../Loading/loading';
import { ModalidadStored } from '../../models/ModalidadStored';

registerLocale('es', es);

const DatosPersonalesPage = () => {
  const manager = useStorageManager();
  const [loading, setLoading] = useState(false);

  const datosPersonales =
    manager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);

  const modalidadkey = manager.get<ModalidadStored>(MODALIDAD_KEY);

  if (!modalidadkey) {
    window.location.href = homePath;
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    control,
    watch,
  } = useForm<DatosPersonales>({
    resolver: yupResolver(datosPersonalesValidation),
    mode: 'onBlur',
    defaultValues: {
      numeroDocumento: datosPersonales?.numeroDocumento,
      nombres: datosPersonales?.nombres,
      primerApellido: datosPersonales?.primerApellido,
      segundoApellido: datosPersonales?.segundoApellido,
      fechaNacimiento:
        datosPersonales && new Date(datosPersonales?.fechaNacimiento),
      paisNacimiento: datosPersonales?.paisNacimientoDescripcion ?? '',
      domicilio: datosPersonales?.domicilio,
      domicilioZona: datosPersonales?.domicilioZona,
      correoElectronico: datosPersonales?.correoElectronico,
      celular: datosPersonales?.celular,
      referencia: datosPersonales?.referencia,
      tieneDiscapacidad: datosPersonales?.tieneDiscapacidad,
      descripcionDiscapacidad: datosPersonales?.descripcionDiscapacidad,
      // codigoVerificacionCelular: datosPersonales?.codigoVerificacionCelular,
      // codigoVerificacionCorreo: datosPersonales?.codigoVerificacionCorreo,
      correoVerficado: datosPersonales?.correoVerficado,
      celularVerificado: datosPersonales?.celularVerificado,
    },
  });
  const watchContacto = watch(['correoElectronico', 'celular']);
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const [correoVerificado, setCorreoVerificado] = useState(false);
  const [celularVerificado, setCelularVerificado] = useState(false);
  const [openVerificacionCorreo, setOpenVerificacionCorreo] = useState(false);
  const [openVerificacionCelular, setOpenVerificacionCelular] = useState(false);
  const { addToast } = useToast();
  const [paises, setPaises] = useState<any[]>([]);
  const [tiposDocumento, setTiposDocumento] = useState<any[]>([]);
  const [lugarNacimiento, setLugarNacimiento] = useState<any[]>([]);
  const [lugarDomicilio, setLugarDomicilio] = useState<any[]>([]);
  const [generos, setGeneros] = useState<any[]>([]);
  const [tiposVia, setTiposVia] = useState<any[]>([]);
  const [tiposZona, setTiposZona] = useState<any[]>([]);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [pinIdEmail, setPinIdEmail] = useState('');
  const [pinIdSMS, setPinIdSMS] = useState('');
  const [codigoPais, setCodigoPais] = useState('');
  const [DescripcionPais, setDescripcionPais] = useState('');
  const [codigoLugarNacimiento, setCodigoLugarNacimiento] = useState('');
  const [DescripcionLugarNacimiento, setDescripcionLugarNacimiento] =
    useState('');
  const [codigoLugarResidencia, setCodigoLugarRecidencia] = useState('');
  const [DescripcionLugarResidencia, setDescripcionLugarRecidencia] =
    useState('');
  const paisNacimiento = watch('paisNacimiento');
  const tieneDiscapacidad = watch('tieneDiscapacidad');

  const handleSelectPais = (selectedItem: any) => {
    setCodigoPais(selectedItem.value);
    setDescripcionPais(selectedItem.text);
  };

  const handleSelectLugarNacimiento = (selectedItem: any) => {
    setCodigoLugarNacimiento(selectedItem.value);
    setDescripcionLugarNacimiento(selectedItem.text);
  };

  const handleSelectRecidencia = (selectedItem: any) => {
    setCodigoLugarRecidencia(selectedItem.value);
    setDescripcionLugarRecidencia(selectedItem.text);
  };

  const validateAndSendSMS = async () => {
    setSendingSMS(true);
    const celular = getValues('celular').trim().replaceAll(' ', '');
    if (celular.match(regexCellphone) && enoughDataToSendSMS()) {
      setOpenVerificacionCelular(true);
      const recaptcha = await (recaptchaRef?.current as any).executeAsync();
      try {
        const response = await usjbClient.validacion.enviarSms({
          contacto: celular,
          documentoPostulante: getValues('numeroDocumento')!,
          tipoDocumento: getValues('tipoDocumento'),
          nombrePostulante: getValues('nombres'),
          apellidoPostulante: `${getValues('primerApellido')} ${getValues(
            'segundoApellido',
          )}`,
          recaptcha,
        });
        setPinIdSMS(response.pinId);
      } catch (err) {
        addToast(
          'error',
          'Ocurrió un error al intentar enviar el SMS de confirmación',
        );
      }
    } else {
      addToast('error', 'Por favor completa los datos faltantes');
    }
    setSendingSMS(false);
  };

  const validateAndSendEmail = async () => {
    setSendingEmail(true);
    const email = getValues('correoElectronico').trim().replace(/ +/, '');
    if (email && enoughDataToSendEmail()) {
      setOpenVerificacionCorreo(true);
      try {
        const recaptcha = await (recaptchaRef?.current as any).executeAsync();
        const response = await usjbClient.validacion.enviarEmail({
          contacto: email,
          documentoPostulante: getValues('numeroDocumento')!,
          tipoDocumento: getValues('tipoDocumento'),
          nombrePostulante: getValues('nombres'),
          apellidoPostulante: `${getValues('primerApellido')} ${getValues(
            'segundoApellido',
          )}`,
          recaptcha,
        });
        setPinIdEmail(response.pinId);
      } catch (err) {
        addToast(
          'error',
          'Ocurrió un error al intentar enviar el correo de confirmación',
        );
      }
    } else {
      addToast('error', 'Por favor completa los datos faltantes');
    }
    setSendingEmail(false);
  };
  const watchDataToSendSMS = watch([
    'celular',
    'tipoDocumento',
    'numeroDocumento',
    'nombres',
    'primerApellido',
    'segundoApellido',
  ]);
  const enoughDataToSendSMS = () => {
    return watchDataToSendSMS.every((field) => field && field !== '');
  };
  const watchDataToSendEmail = watch([
    'correoElectronico',
    'tipoDocumento',
    'numeroDocumento',
    'nombres',
    'primerApellido',
    'segundoApellido',
  ]);
  const enoughDataToSendEmail = useCallback(() => {
    return watchDataToSendEmail.every((field) => field && field !== '');
  }, [watchDataToSendEmail]);
  // const watchCodigoVerificacionCorreo = watch('codigoVerificacionCorreo');
  // const watchCodigoVerificacionCelular = watch('codigoVerificacionCelular');
  const mapValues = (
    formValues: DatosPersonales,
  ): DatosPersonalesExtendidos => {
    const tipoDocumentoDescripcion = tiposDocumento
      .find(({ value }) => value === formValues.tipoDocumento)
      ?.text?.toUpperCase();
    const generoDescripcion = generos
      .find(({ value }) => value === formValues.genero)
      ?.text?.toUpperCase();
    const lugarNacimientoDescripcion = DescripcionLugarNacimiento;
    const lugarDomicilioDescripcion = DescripcionLugarResidencia;
    const tipoViaDescripcion = tiposVia
      .find(({ value }) => value === formValues.tipoVia)
      ?.text?.toUpperCase();
    const tipoZonaDescripcion = tiposZona
      .find(({ value }) => value === formValues.tipoZona)
      ?.text?.toUpperCase();
    const paisNacimientoDescripcion = DescripcionPais;

    const paisNacimiento = codigoPais;
    const LugarNacimiento = codigoLugarNacimiento;
    const LugarResidencia = codigoLugarResidencia;
    return {
      ...formValues,
      nombres: formValues.nombres.toUpperCase(),
      primerApellido: formValues.primerApellido.toUpperCase(),
      segundoApellido: formValues.segundoApellido.toUpperCase(),
      correoElectronico: formValues.correoElectronico.toLowerCase(),
      referencia: formValues.referencia?.toUpperCase(),
      domicilio: formValues.domicilio.toUpperCase(),
      tipoDocumentoDescripcion,
      generoDescripcion,
      lugarNacimientoDescripcion,
      lugarNacimiento: LugarNacimiento,
      lugarDomicilioDescripcion,
      ubigeoDomicilio: LugarResidencia,
      tipoViaDescripcion,
      tipoZonaDescripcion,
      paisNacimientoDescripcion,
      paisNacimiento: paisNacimiento,
      codigoPais,
      DescripcionPais,
      codigoLugarNacimiento,
      DescripcionLugarNacimiento,
      codigoLugarResidencia,
      DescripcionLugarResidencia,
    };
  };
  const onRequest = async () => {
    console.log('on Request');
    setLoading(true);
    const formValues = mapValues(getValues());
    const MySwal = withReactContent(Swal);

    const respuesta = await usjbClient.postulante.validacionPostulacion({
      dni: formValues.numeroDocumento.toString(),
      proceso: modalidadkey.idProceso.toString(),
      semestre: modalidadkey.idSemestre.toString(),
    });

    setLoading(false);
    if (respuesta.validacion + '' !== '0') {
      MySwal.fire({
        title: '<strong>Postulación en curso</strong>',
        html: '<i>Estimado postulante, ya cuenta con un registro de inscripción, quedando pendiente el pago por dicho concepto el cual podrá realizarlo en línea a través de los bancos autorizados. <a style="color: blue; text-decoration: underline; cursor: pointer;" href="https://upsjbhelp.freshdesk.com/support/solutions/articles/63000279782-realizar-pagos-online-en-los-bancos-autorizados">Ver</a></i>',
        icon: 'info',
        confirmButtonColor: '#F43F5E',
      });
      return;
    }

    setLoading(true);
    const postulante = await usjbClient.postulante.obtenerEmplId({
      numDoc: formValues.numeroDocumento.toString(),
    });

    setLoading(false);
    var emplid: string;
    if (postulante && postulante.status === 'OK') {
      emplid = postulante.result.codigoalumno;
      if (emplid !== null && emplid !== '') {
        const postulanteRegistrado =
          await usjbClient.postulante.consultarPostulacion({
            emplId: emplid,
          });
        if (postulanteRegistrado.status === 'OK') {
          const postulantesFiltrados = postulanteRegistrado.result.filter(
            (postulante) => {
              return postulante.grado === 'PREG' && postulante.ciclo === '2241';
            },
          );
          if (postulantesFiltrados.length > 0) {
            MySwal.fire({
              title: '<strong>Postulación en curso</strong>',
              html: '<i>Estimado postulante, ya cuenta con un registro de inscripción, quedando pendiente el pago por dicho concepto el cual podrá realizarlo en línea a través de los bancos autorizados. <a style="color: blue; text-decoration: underline; cursor: pointer;" href="https://upsjbhelp.freshdesk.com/support/solutions/articles/63000279782-realizar-pagos-online-en-los-bancos-autorizados">Ver</a></i>',
              icon: 'info',
              confirmButtonColor: buttonColorPink,
            });
            return;
          }
        }
      }
    }

    const finalData = {
      ...formValues,
      lugarNacimiento: formValues.lugarNacimiento ? formValues.lugarNacimiento : '',
      lugarNacimientoDescripcion: formValues.lugarNacimientoDescripcion ? formValues.lugarNacimientoDescripcion : '',
    }
    console.log("finaldata", finalData);
    manager.set(DATOS_PERSONALES_KEY, finalData);
    navigate('/requisitos?paso=estudios');
  };
  const onSubmit = async () => {
    console.log('checking');
    const formValues = mapValues(getValues());
    const MySwal = withReactContent(Swal);

    setLoading(true);

    const zeroPhase = await usjbClient.postulante.validacionDeudaExistente({
      tipoDocumento: formValues.tipoDocumento.toString(),
      numeroDocumento: formValues.numeroDocumento.toString(), //70460733/SF001
    });
    setLoading(false);

    if (!zeroPhase.SJB_VAL_DEU_DNI_RESP) {
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
      return;
    }

    if (zeroPhase.SJB_VAL_DEU_DNI_RESP.CodigoRespuesta === 0) {
    } else if (zeroPhase.SJB_VAL_DEU_DNI_RESP.CodigoRespuesta === 1) {
      if (!zeroPhase.SJB_VAL_DEU_DNI_RESP.SJB_VAL_DEU_DNI_RES) {
        connectionErrorDialog(requestErrorTitle, requestErrorDescription);
        return;
      }
      const deuda = zeroPhase.SJB_VAL_DEU_DNI_RESP.SJB_VAL_DEU_DNI_RES.find(
        (e: any) => e.DEUDA,
      );
      const html = `Se ha detectado que tienes una deuda pendiente. Para poder continuar con el proceso de inscripción, es necesario que regularices tus pagos. Si deseas obtener más detalles sobre tu deuda, te recomendamos que consultes con un ejecutivo de admisión.`;

      if (deuda) {
        endMessageDialog(html);
        return;
      }
    } else {
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
      return;
    }

    const firstPhase =
      await usjbClient.postulante.validacionPostulacionEstudianteExistente({
        tipoDocumento: formValues.tipoDocumento.toString(),
        numeroDocumento: formValues.numeroDocumento.toString(), //"07872106"//
      });

    setLoading(false);
    if (!firstPhase.SJB_ESTU_ADMIN_ACT_RESP) {
      console.log('Entro 4=>');
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
      return;
    }
    if (firstPhase.SJB_ESTU_ADMIN_ACT_RESP.CodigoRespuesta === 0) {
      onRequest();
    } else if (firstPhase.SJB_ESTU_ADMIN_ACT_RESP.CodigoRespuesta === 1) {
      if (!firstPhase.SJB_ESTU_ADMIN_ACT_RESP.SJB_ESTU_ADMIN_ACT) {
        connectionErrorDialog(requestErrorTitle, requestErrorDescription);
        return;
      }
      const estudios = manager.get<DatosEstudios>(ESTUDIOS_KEY);
      const found = firstPhase.SJB_ESTU_ADMIN_ACT_RESP.SJB_ESTU_ADMIN_ACT.find(
        (e: any) => e.ACAD_PROG === estudios.carreraSeleccionada,
      );
      if (found) {
        const html = `Estas intentando postularte al programa de \
          <strong>${found.DESCR_ACAD_PROG}</strong>, en el cual actualmente tienes una situación activa. \
          Para poder continuar con tu proceso de inscripción, debes solicitar tu retiro \
          voluntario.`;

        endMessageDialog(html);
      } else {
        const programa_s =
          firstPhase.SJB_ESTU_ADMIN_ACT_RESP.SJB_ESTU_ADMIN_ACT.map(
            (e: any) => e.DESCR_ACAD_PROG,
          ).join(', ');
        const html = `<div class='text-justify'>Actualmente estás inscrito en el/los programa(s) de estudio(s) <strong>${programa_s}</strong>. <br />\
        Te recomendamos solicites tu retiro voluntario para evitar duplicidad de información en tu historial académico.</div>`;
        MySwal.fire({
          title: '<strong>Vaya!</strong>',
          html: `<div  class='text-justify'>${html}</div>`,
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: buttonColorPink,
          confirmButtonText: `Continuar`,
          reverseButtons: true,
          denyButtonText: `<a href='${homePath}/estudios?paso=carrera' target='_self'>Retornar</a>`,
          showDenyButton: true,
          denyButtonColor: buttonColorGray,
        }).then((result) => {
          if (result.isConfirmed) {
            onRequest();
          }
          if (result.isDenied) {
            window.location.href = `${homePath}/estudios?paso=carrera`;
          }
        });
      }
      return;
    } else {
      console.log('Entro 6=>');
      connectionErrorDialog(requestErrorTitle, requestErrorDescription);
      return;
    }
  };
  useEffect(() => {
    const run = async () => {
      const paises = await obtenerPaises();
      setPaises(paises);
      const tiposDocumento = await obtenerTiposDocumento();
      setTiposDocumento(tiposDocumento);

      setCodigoPais(datosPersonales?.codigoPais);
      setDescripcionPais(datosPersonales?.DescripcionPais);

      setCodigoLugarNacimiento(datosPersonales?.codigoLugarNacimiento);
      setDescripcionLugarNacimiento(
        datosPersonales?.DescripcionLugarNacimiento,
      );

      setCodigoLugarRecidencia(datosPersonales?.codigoLugarResidencia);
      setDescripcionLugarRecidencia(
        datosPersonales?.DescripcionLugarResidencia,
      );

      const lugarNacimiento = await obtenerUbigeo();

      setLugarNacimiento(lugarNacimiento);
      setLugarDomicilio(lugarDomicilio);
      const generos = await obtenerGeneros();
      setGeneros(generos);
      const vias = await usjbClient.parametro.obtenerVia();
      setTiposVia(vias.map((via) => ({ text: via.texto, value: via.valor })));
      const zonas = await usjbClient.parametro.obtenerTipoZona();
      setTiposZona(
        zonas.map((zona) => ({ text: zona.texto, value: zona.valor })),
      );
    };
    run();
  }, [setValue]);
  useEffect(() => {
    if (tiposDocumento.length > 0) {
      const finalValue =
        datosPersonales?.tipoDocumento ??
        tiposDocumento.find(({ selected }) => selected)?.value ??
        '';
      setValue('tipoDocumento', finalValue);
    }
  }, [tiposDocumento, setValue, datosPersonales?.tipoDocumento]);
  useEffect(() => {
    if (generos.length > 0) {
      setValue('genero', datosPersonales?.genero);
    }
  }, [generos, setValue, datosPersonales?.genero]);
  useEffect(() => {
    if (paises.length > 0) {
      setValue('paisNacimiento', datosPersonales?.DescripcionPais ?? '');
    }
  }, [paises, setValue, datosPersonales?.DescripcionPais]);
  useEffect(() => {
    if (lugarNacimiento.length > 0) {
      setValue(
        'lugarNacimiento',
        datosPersonales?.DescripcionLugarNacimiento ?? '',
      );
    }
  }, [lugarNacimiento, setValue, datosPersonales?.DescripcionLugarNacimiento]);
  useEffect(() => {
    if (lugarNacimiento.length > 0) {
      setValue(
        'ubigeoDomicilio',
        datosPersonales?.DescripcionLugarResidencia ?? '',
      );
    }
  }, [lugarNacimiento, setValue, datosPersonales?.DescripcionLugarResidencia]);
  useEffect(() => {
    // if (lugarNacimiento.length > 0) {
    //   setValue('lugarNacimiento', datosPersonales?.DescripcionLugarNacimiento);
    // }

    // setValue('ubigeoDomicilio', datosPersonales?.DescripcionLugarResidencia);

    if (tiposVia.length > 0) {
      setValue('tipoVia', datosPersonales?.tipoVia);
    }
    if (tiposZona.length > 0) {
      setValue('tipoZona', datosPersonales?.tipoZona);
    }
  }, [tiposVia, tiposZona, setValue, datosPersonales]);
  useEffect(() => {
    if (watchContacto[0]?.length > 0 && datosPersonales?.correoVerficado) {
      setCorreoVerificado(watchContacto[0] === datosPersonales.correoVerficado);
    }
    if (watchContacto[1]?.length > 0 && datosPersonales?.celularVerificado) {
      setCelularVerificado(
        watchContacto[1] === datosPersonales.celularVerificado,
      );
    }
  }, [watchContacto, datosPersonales]);
  return (
    <div className="flex justify-center mb-5">
      <div className="w-10/12 mx-auto">
        <h3 className="text-2xl col-span-2 text-left my-6-per text-rose-500 text-gray-400 text-lg">
          Datos Personales y de Contacto:
        </h3>
        {/* <Modal
          open={openVerificacionCorreo}
          setOpen={setOpenVerificacionCorreo}
          leftButtonMessage="Validar"
          leftButtonDisabled={
            !watchCodigoVerificacionCorreo ||
            watchCodigoVerificacionCorreo?.length < 6
          }
          onClickLeftButton={async () => {
            const recaptcha = await (
              recaptchaRef?.current as any
            ).executeAsync();
            const codigoVerificacionCorreo = getValues(
              'codigoVerificacionCorreo',
            );
            console.log('verificando', {
              pinId: pinIdEmail,
              pin: codigoVerificacionCorreo,
              recaptcha,
            });
            try {
              const verificacion = await usjbClient.validacion.verificarEmail({
                pinId: pinIdEmail,
                pin: codigoVerificacionCorreo,
                recaptcha,
              });
              if (verificacion.mensajeStatus === 'VALID') {
                console.log('setting correoVerificado true');
                setCorreoVerificado(true);
                setValue('correoVerficado', getValues('correoElectronico'));
              }
              return true;
            } catch (err) {
              console.error(err);
              addToast('error', 'Código de verificación inválido');
              return false;
            }
          }}
        >
          <div className="text-sm">
            Se ha enviado un código de verificación a su correo
          </div>
          <TextInput
            className="md:col-span-3 col-span-6"
            label="Ingrese el código de verificación:"
            placeholder="888888"
            error={errors.codigoVerificacionCorreo}
            {...register('codigoVerificacionCorreo')}
          />
        </Modal>
        <Modal
          open={openVerificacionCelular}
          setOpen={setOpenVerificacionCelular}
          leftButtonMessage="Validar"
          leftButtonDisabled={watchCodigoVerificacionCelular?.length < 6}
          onClickLeftButton={async () => {
            const recaptcha = await (
              recaptchaRef?.current as any
            ).executeAsync();
            const codigoVerificacionCelular = getValues(
              'codigoVerificacionCelular',
            );
            console.log('verificando', {
              pinId: pinIdSMS,
              pin: codigoVerificacionCelular,
              recaptcha,
            });
            try {
              const verificacion = await usjbClient.validacion.verificarSms({
                pinId: pinIdSMS,
                pin: codigoVerificacionCelular,
                recaptcha,
              });
              if (verificacion.mensajeStatus === 'true') {
                setCelularVerificado(true);
                setValue('celularVerificado', getValues('celular'));
                return true;
              } else {
                addToast('error', 'Código de verificación inválido');
                return false;
              }
            } catch (err) {
              console.error(err);
              addToast('error', 'Código de verificación inválido');
              return false;
            }
          }}
        >
          <div className="text-sm">
            Se ha enviado un código de verificación a su celular
          </div>
          <TextInput
            className="md:col-span-3 col-span-6"
            label="Ingrese el código de verificación:"
            placeholder="888888"
            error={errors.codigoVerificacionCelular}
            {...register('codigoVerificacionCelular')}
          />
        </Modal> */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="my-20 grid grid-cols-6 gap-6 origin-center w-full mx-w-full text-sm"
        >
          {/* <Select
            className="md:col-span-3 col-span-6"
            label="Tipo de documento de identidad*"
            options={tiposDocumento}
            error={errors.tipoDocumento}
            {...register('tipoDocumento')}
          /> */}

          <Select
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Tipo de documento de identidad"
            required={true}
            options={[
              {
                text: 'CARNET EXT',
                value: '04',
              },
              {
                text: 'DNI',
                value: '01',
              },
              {
                text: 'PASAPORTE',
                value: '07',
              },
            ]}
            error={errors.tipoDocumento}
            {...register('tipoDocumento')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Número de documento de identidad"
            required={true}
            maxLength={getValues('tipoDocumento') === '01' ? 8 : 20}
            placeholder="88888888"
            error={errors.numeroDocumento}
            {...register('numeroDocumento')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            autoComplete="off"
            label="Nombres"
            required={true}
            error={errors.nombres}
            placeholder=""
            {...register('nombres')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Primer apellido"
            required={true}
            error={errors.primerApellido}
            {...register('primerApellido')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Segundo apellido"
            required={true}
            error={errors.segundoApellido}
            {...register('segundoApellido')}
          />

          <div className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2">
            <Controller
              name="fechaNacimiento"
              control={control}
              render={({ field }) => (
                <ReactDatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  maxDate={MAX_FECHA_NACIMIENTO}
                  dateFormat="dd/MM/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale="es"
                  required={true}
                  onFocus={(e) => (e.target.readOnly = true)}
                  customInput={
                    <TextInput
                      label="Fecha de nacimiento"
                      error={errors.fechaNacimiento}
                    />
                  }
                />
              )}
            ></Controller>
          </div>

          <Select
            required={true}
            className="col-span-6 sm:col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Sexo"
            options={generos}
            error={errors.genero}
            {...register('genero')}
          />

          <Autocomplete
            required={true}
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="País de nacimiento"
            options={paises}
            placeholder="Escriba su país de nacimiento..."
            error={errors.paisNacimiento}
            {...register('paisNacimiento')}
            onSelect={handleSelectPais}
          />

          <div className="col-span-6 md:col-span-.5 flex items-center mx-auto">
            <div className="flex items-center">
              <input
                className="h-5 w-5 mr-5 accent-rose-500"
                id="tieneDiscapacidad"
                type="checkbox"
                {...register('tieneDiscapacidad')}
              ></input>
              <label htmlFor="tieneDiscapacidad">
                Tengo alguna discapacidad
              </label>
              <Tooltip>
                Recuerde que usted puede presentarse a través de la modalidad de
                examen especial “PERSONAS CON DISCAPACIDAD” Ley N°29973 Ley
                General de la Persona con Discapacidad- Art. 38°
              </Tooltip>
            </div>
            {tieneDiscapacidad && (
              <Select
                required={true}
                className="col-span-6 md:col-span-1"
                label="Describa la discapacidad que tiene"
                options={[
                  {
                    text: 'MOTRIZ',
                    value: 'MOTRIZ',
                  },
                  {
                    text: 'SENSORIAL',
                    value: 'SENSORIAL',
                  },
                  {
                    text: 'MENTAL',
                    value: 'MENTAL',
                  },
                  {
                    text: 'APRENDIZAJE',
                    value: 'APRENDIZAJE',
                  },
                ]}
                error={errors.descripcionDiscapacidad}
                {...register('descripcionDiscapacidad')}
              />
            )}
          </div>

          {paisNacimiento === '' ||
            paisNacimiento === 'Perú' ||
            paisNacimiento === 'PER' ? (
            <Autocomplete
              required={true}
              className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
              label="Lugar de nacimiento"
              {...register('lugarNacimiento')}
              placeholder="Escriba su lugar de nacimiento..."
              options={lugarNacimiento}
              error={errors.paisNacimiento}
              onSelect={handleSelectLugarNacimiento}
            />
          ) : (
            <></>
          )}

          <Autocomplete
            required={true}
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Lugar de residencia"
            {...register('ubigeoDomicilio')}
            placeholder="Escriba su lugar de residencia..."
            options={lugarNacimiento}
            error={errors.ubigeoDomicilio}
            onSelect={handleSelectRecidencia}
          />

          <Select
            required={true}
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Tipo de vía"
            options={tiposVia}
            error={errors.tipoVia}
            {...register('tipoVia')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Domicilio"
            required={true}
            error={errors.domicilio}
            {...register('domicilio')}
          />

          <Select
            required={false}
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Tipo de zona"
            options={tiposZona}
            error={errors.tipoZona}
            {...register('tipoZona')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Zona"
            error={errors.domicilioZona}
            {...register('domicilioZona')}
          />

          <TextInput
            className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
            label="Referencia"
            error={errors.referencia}
            {...register('referencia')}
          />

          <div className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2">
            <TextInput
              required={true}
              autoComplete="off"
              className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
              label="Correo electrónico"
              error={errors.correoElectronico}
              {...register('correoElectronico')}
            />
            {/* <div className="col-span-2 md:col-span-2">
              {' '}
              {correoVerificado ? (
                <IconoConfirmado />
              ) : (
                <div className="col-span-2 md:col-span-1 ">
                  <Button
                    className="px-2 py-1"
                    type="button"
                    onClick={validateAndSendEmail}
                    disabled={!enoughDataToSendEmail() || sendingEmail}
                  >
                    Enviar
                  </Button>
                </div>
              )}
            </div> */}
          </div>

          <div className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2">
            <TextInput
              autoComplete="off"
              className="col-span-6 md:col-span-6 xl:col-span-3 2xl:col-span-2"
              label="Celular"
              required={true}
              error={errors.celular}
              type="number"
              {...register('celular')}
            />
            {/* <div className="col-span-2 md:col-span-2">
              {celularVerificado ? (
                <IconoConfirmado />
              ) : (
                <div>
                  <Button
                    className="px-2 py-1"
                    type="button"
                    onClick={validateAndSendSMS}
                    disabled={!enoughDataToSendSMS() || sendingSMS}
                  >
                    Enviar
                  </Button>
                </div>
              )}
            </div> */}
          </div>

          {loading && <Loading />}
          <div className="flex justify-center col-span-6 gap-x-6">
            <Button
              hoverbackgroundcolor="upsjb-turquoise-hovering"
              type="button"
              onClick={() => {
                navigate('/modalidades-pregrado?paso=modalidad');
              }}
              className="flex items-center"
            >
              <ArrowCircleLeftIcon
                className="text-white rounded-full h-8 w-8 hover:shadow mr-4"
                aria-hidden="true"
              />
              Regresar
            </Button>
            <ContinueButton type="submit" />
          </div>
          {false && (
            <ReCAPTCHA
              ref={recaptchaRef as any}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
            />
          )}
        </form>{' '}
        <div>
          <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md">
            <p className="text-red-600">Nota:</p>
            <p className="nota text-gray-600">
              <strong className="upsjb-color-red">*</strong> Campos
              obligatorios.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatosPersonalesPage;
