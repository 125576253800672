import classNames from 'classnames';
import { useState, ReactNode } from 'react';

type TabsProps = {
  tabs: {
    name: string;
    current?: boolean;
    content?: ReactNode;
  }[];
};

const Tabs = ({ tabs }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(
    tabs.find(({ current }) => !!current)?.name ?? tabs[0].name,
  );
  return (
    <div className="flex flex-col items-center">
      <div className="w-full">
        <div>
          <nav
            className="flex"
            aria-label="Tabs"
          >
            {tabs.map((tab, i) => (
              <div
                key={tab.name}
                className={classNames(
                  tab.name === activeTab
                    ? 'upsjb-turquoise text-white'
                    : 'bg-gray-100 text-black upsjb-turquoise-hovering hover:text-white',
                  'grow px-3 py-2 font-medium text-sm text-center flex justify-center items-center cursor-pointer transition-all duration-300 rounded-t-lg',
                )}
                aria-current={tab.name === activeTab ? 'page' : undefined}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="w-full justify-center p-2 pt-4 md:p-6 rounded-b-lg text-xl">
        {tabs.find(({ name }) => name === activeTab)?.content}
      </div>
    </div>
  );
};

export default Tabs;
