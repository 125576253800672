import { useEffect, useState } from 'react';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import useQuestionParser from '../../hooks/useQuestionParser';
import { subirEncuesta } from '../../api/survey';
import { useToast } from '../../hooks/useToast';
import { useForm } from 'react-hook-form';
import usjbClient from "../../sdk";

const Encuesta = () => {
  const questionParser = useQuestionParser();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [encuesta, setEncuesta] = useState<any>(null);
  const {
    register,
    handleSubmit,
    setValue
  } = useForm({ mode: 'all' });

  const registrarBoleta = (tokenSeguridad: string,
  eticket:string,
  transactionToken:string,
  codigoComercio:string,
  nroRecibo:string,
  impTotalPagar:string) => {
    return usjbClient.postulante.registrarRecibo({
      tokenSeguridad: tokenSeguridad,
      eticket:eticket,
      transactionToken:transactionToken,
      codigoComercio:codigoComercio,
      nroRecibo:nroRecibo,
      impTotalPagar:impTotalPagar,
    })
  };

  const onSubmit = async (data: { [key: string]: any }) => {
    const answers: { [key: string]: any } = {};
    Object.entries(data).forEach(([key, value]) => {
      answers[key] = value;
    });
    await subirEncuesta({ answers });
    addToast('success', 'Encuesta registrada con éxito!');
    navigate('/login');
  };

  const obtenerParametro = (name:string) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  useEffect(() => {
    const init = async () => {
      //const encuesta = await obtenerEncuesta();
      //setEncuesta(encuesta);
      const registro = await registrarBoleta(
          localStorage.getItem("tokenSeguridad") as string,
          localStorage.getItem("eticket") as string,
          localStorage.getItem("transactionToken") as string,
          //obtenerParametro("qerts12") as string,
          localStorage.getItem("merchantId") as string,
          localStorage.getItem("nroRecibo") as string,
          localStorage.getItem("impTotalPagar") as string,
          
      );
    };
    init();
  }, []);
  return (
    <>
      <div className="flex justify-center">
        <div>
          <h2 className="text-xl col-span-2 text-center my-6 text-rose-500">
            ENCUESTA
          </h2>
          <h2 className="text-2xl col-span-2 text-center my-6 text-rose-500">
            {encuesta?.title}
          </h2>
          <div className="grid items-center gap-6 md:w-fit">
            <form onSubmit={handleSubmit(onSubmit)}>
              {encuesta &&
                encuesta.options.questions.map((question: any) => (
                  <div className="col-span-2">
                    {questionParser(question, { register, setValue })}
                  </div>
                ))}
              <div className="col-span-2 flex justify-center my-6">
                <Button className="flex items-center" type="submit">
                  <ArrowCircleRightIcon
                    className="text-white rounded-full h-8 w-8 hover:shadow mr-4"
                    aria-hidden="true"
                  />
                  Enviar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Encuesta;
