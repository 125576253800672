import { FC, useState } from 'react';
import { StarIcon } from '@heroicons/react/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import { useEffect } from 'react';

export interface RatingProps {
  value: number;
  name: string;
  label?: string;
  minRating?: number;
  maxRating?: number;
  onChanged?: (value: number) => void;
  setValue: (...args: any[]) => any;
}

const Rating: FC<RatingProps> = (props: RatingProps) => {
  const [stars, setStars] = useState<boolean[]>(
    new Array(props.maxRating || 5).fill(false),
  );
  useEffect(() => {
    setStars((stars) => stars.map((star, index) => index < props.value));
  }, [props.value]);

  const setStarValue = (value: number) => {
    setStars(stars.map((star, index) => index < value));
    props.onChanged?.(value);
    props.setValue(props.name, value);
  };
  return (
    <>
      {props.label ? (
        <label className="col-span-2 md:col-span-1">
          {props.label} del {props.minRating} al {props.maxRating}:
        </label>
      ) : (
        <></>
      )}
      <div className="flex" aria-label="Calificar" title="Calificar">
        {stars.map((star, index) => {
          return star ? (
            <button key={index} onClick={() => setStarValue(index + 1)} type="button">
              <StarIconSolid
                data-value={star}
                className="h-8 text-yellow-400"
              ></StarIconSolid>
            </button>
          ) : (
            <button key={index} onClick={() => setStarValue(index + 1)}>
              <StarIcon
                data-value={star}
                className="h-8 text-yellow-400"
              ></StarIcon>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default Rating;
