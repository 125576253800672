import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Step, StepProps } from '../../atoms/Step/Step';
import { PASOS } from '../../constants/pasos';
import { BANNERS } from '../../constants/banners';

export const StepperBanner = () => {
  const [pasos, setPasos] = useState<StepProps[]>(PASOS);
  const [searchParams] = useSearchParams();
  const pasoUrl = searchParams.get('paso') || '';
  const pasoIndex = pasos.findIndex((paso) => paso.code === pasoUrl);
  useEffect(() => {
    setPasos((pasos) =>
      pasos.map((paso, index) => {
        return {
          ...paso,
          active: index === pasoIndex,
          done: index < pasoIndex,
        };
      }),
    );
  }, [pasoIndex]);

  return (
    <div data-testid="stepper" className="w-full">
      <div className="w-2/3 mx-auto h-1 top-4 md:top-8 relative -z-10"></div>
      <div className="flex justify-center z-2">
        <img src={BANNERS.find((banner) => banner.stepCode === pasos
          .find((step) => step.active)?.code)?.imageSrc ?? 'img/people/alumnos.png'} />
      </div>
    </div>
  );
};
