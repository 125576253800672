import { Link } from 'react-router-dom';
import Button from '../../atoms/Button/Button';

const InfoInscripcion = () => {
  return (
    <div className="flex justify-center">
      <div className="text-center">
        <h1 className="text-3xl col-span-2 text-center my-6 text-rose-500">
          INFORMACIÓN DE LA INSCRIPCIÓN
        </h1>
        <p>Te has inscrito correctamente a la carrera:</p>
        <strong>Ingeniería en Computación</strong>
        <p>Te debe haber llegado un correo de confirmación</p>
        <p>Fecha de resultados: 08/03/2025</p>
        <br/>
        <p>Tienes observaciones en tus requisitos.</p>
        <div className="flex justify-center my-6">
          <Link to="/requisitos">
            <Button>Resolver ahora</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InfoInscripcion;
