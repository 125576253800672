import { Outlet } from 'react-router-dom';
import GlobalHeader from '../GlobalHeader/GlobalHeader';

const LogoWrapper = () => {
  return (
    <>
      <GlobalHeader />
      <div className='mt-2 mb-4 mx-4 my-4'>
        <div className="-z-30 fixed p-20 top-0 left-0 -translate-x-1/2 -translate-y-1/2 h-full w-full rounded-full bg-gradient-radial from-rose-50 via-white to-white"></div>
        <div className="-z-40 fixed p-20 bottom-0 right-0 translate-x-1/2 translate-y-1/2 rounded-full h-2-full w-2-full bg-gradient-radial from-rose-200 via-white to-white"></div>
        {false && <><div className="flex justify-center items-center">
          <img
            src="img/logos-upsjb_2022.png"
            alt="Logo UPSJB"
            className="md:w-1/3 sm:w-1/2 mr-2"
          ></img>
        </div>
          <h1 className="text-center text-3xl text-rose-500 font-bold my-4">
            INSCRIPCIÓN DE POSTULANTES UPSJB
          </h1></>}
        <div className='wrapper-body'>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default LogoWrapper;
