import Dexie, { Table } from 'dexie';

export interface RequirementFiles {
  id?: number;
  dni: string;
  idRequisito: string;
  nombreRequisito: string;
  base64: string;
  contentType: string;
  fileName: string;
}

export class MyIndexDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  requirements!: Table<RequirementFiles>;

  constructor() {
    super('UPSJB_ADMISION_REQUIREMENT_DB');
    this.version(1).stores({
      requirements: '++id, dni, idRequisito, nombreRequisito, base64, contentType, fileName' // Primary key and indexed props
    });
  }
}

export const IndexService = new MyIndexDexie();