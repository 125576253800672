import { TextInput } from '../atoms/TextInput/TextInput';
import { Question } from '../constants/encuesta';
import Rating from '../molecules/Rating/Rating';

const useQuestionParser =
  () =>
  (
    question: Question,
    { register, setValue }: { register: any; setValue: any },
  ) => {
    console.log('question', question);
    if (question.type === 'ranking.stars') {
      return (
        <Rating
          name={question.id}
          key={question.title}
          value={0}
          minRating={question.rankingMin}
          maxRating={question.rankingMax}
          label={question.title}
          setValue={setValue}
        ></Rating>
      );
    } else if (question.type === 'checkbox') {
      return (
        <>
          {question.title}
          {question.options?.map((option) => (
            <div key={option.value}>
              <input
                className="h-5 w-5 accent-rose-500"
                type="checkbox"
                id={option.value}
                value={option.value}
                {...register(question.id)}
              />
              <label className="mx-2" htmlFor={option.value}>
                {option.text}
              </label>
            </div>
          ))}
        </>
      );
    } else if (question.type === 'textArea') {
      return (
        <TextInput
          key={question.title}
          type="textarea"
          rows={4}
          label={question.title}
          {...register(question.id)}
        />
      );
    }
  };

export default useQuestionParser;
