import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Loading from './../Loading/loading';
import {
  DATOS_PERSONALES_KEY,
  DEUDA_PAGO_KEY,
  ESTUDIOS_KEY,
  MODALIDAD_KEY,
  REQUISITOS_KEY,
} from '../../constants/keys';
import MENSAJES from '../../constants/messages';
import { useStorageManager } from '../../hooks/useStateManager';
import { useToast } from '../../hooks/useToast';
import { DatosEstudios } from '../../models/DatosEstudios';
import usjbClient from '../../sdk';
import { DatosPersonalesExtendidos } from '../../validations/datos-personales.validation';
import { DatosRequisitosExtendidos } from '../../validations/requisitos.validation';
import Tabs from './Tabs';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  buttonColorPink,
  categoryPregrado,
  categoryResidentado,
  homePath,
  modalityIAT,
} from '../../utils/strings';
import Modal from '../../molecules/Modal/Modal';
import { ModalidadStored } from '../../models/ModalidadStored';
import { useLiveQuery } from 'dexie-react-hooks';
import { IndexService, RequirementFiles } from '../../services/index.service';

const errorData = (message: string) => {
  return (
    <div>
      <span className="text-red-500">Error: </span>
      {message}
    </div>
  );
};
const Resumen = () => {
  const manager = useStorageManager();
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [cargandoPostulacion, setCargandoPostulacion] = useState(false);
  const storageManager = useStorageManager();
  const datosPersonales =
    storageManager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);
  const requisitos =
    storageManager.get<DatosRequisitosExtendidos>(REQUISITOS_KEY);
  const modalidad = storageManager.get<ModalidadStored>(MODALIDAD_KEY);
  const estudios = storageManager.get<DatosEstudios>(ESTUDIOS_KEY);
  // const [indexedRequirements, setIndexedRequirements] = useState<
  //   ArchivoRequisitoExtendido[]
  // >([]);

  const archivosRequisitos = useLiveQuery(() =>
    IndexService.requirements
      .where({ dni: datosPersonales.numeroDocumento })
      .toArray(),
  );

  const obtenerPrecio = (emplId: string, modalidadId: string) => {
    return usjbClient.postulante.generarDeuda({
      emplid: emplId,
      modalidadId: modalidadId,
    });
  };

  const registrarPostulante = (recaptcha: string) => {
    return usjbClient.postulante.registrarPostulante({
      postulante: {
        turno: modalidad.turno,
        turnoSdo: modalidad.turnoSegundaOpcion,
        tipoDocumento: datosPersonales.tipoDocumento,
        documentoPostulante: datosPersonales.numeroDocumento!,
        nombre: datosPersonales.nombres,
        apellidoPaterno: datosPersonales.primerApellido,
        apellidoMaterno: datosPersonales.segundoApellido,
        genero: datosPersonales.genero,
        idEstadoCivil: 'S',
        idColegio: requisitos.colegioId || requisitos.colegioIdDescripcion,
        ubigeoDomicilio: String(datosPersonales.ubigeoDomicilio),
        fechaNacimiento: String(datosPersonales.fechaNacimiento),
        paisNacimiento: datosPersonales.paisNacimiento,
        ubigeoNacimiento: String(datosPersonales.lugarNacimiento),
        carreraPrimeraOpcion: estudios.carreraSeleccionada,
        local: estudios.sucursalSeleccionada,
        annioTermino: String(requisitos.anioFinColegio),
        carreraSegundaOpcion: estudios.carrera2daSeleccionada,
        correo: datosPersonales.correoElectronico,
        celular: datosPersonales.celular,
        idVia: datosPersonales.tipoVia,
        domicilioVia: datosPersonales.tipoViaDescripcion,
        idTipoZona: datosPersonales.tipoZona || '',
        domicilioZona: datosPersonales.domicilioZona || '',
        direccion: datosPersonales.domicilio,
        referencia: datosPersonales.referencia || '',
        discapacidad: datosPersonales.tieneDiscapacidad,
        discapacidadDescripcion:
          datosPersonales.descripcionDiscapacidad !== undefined
            ? String(datosPersonales.descripcionDiscapacidad)
            : '',
      },
      idProceso: modalidad.idProceso,
      requisitos: (archivosRequisitos ?? []).map(
        (archivo: RequirementFiles) => ({
          idRequisito: parseInt(archivo.idRequisito),
          base64: archivo.base64,
          contentType: archivo.contentType,
        }),
      ),
      //recaptcha,
    });
  };

  const confirmarRegistro = async () => {
    // setShowModal(true);

    setCargandoPostulacion(true);
    try {
      const MySwal = withReactContent(Swal);
      const datosPersonales =
        storageManager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);
      const postulante = await usjbClient.postulante.obtenerEmplId({
        numDoc: datosPersonales.numeroDocumento.toString(),
      });

      var emplid: string;
      if (postulante && postulante.status === 'OK') {
        emplid = postulante.result.codigoalumno;
        if (emplid !== null && emplid !== '') {
          const postulanteRegistrado =
            await usjbClient.postulante.consultarPostulacion({
              emplId: emplid,
            });
          console.log('respuesta==>', postulanteRegistrado);
          if (postulanteRegistrado.status === 'OK') {
            const postulantesFiltrados = postulanteRegistrado.result.filter(
              (postulante) => {
                return (
                  postulante.grado === 'PREG' && postulante.ciclo === '2241'
                );
              },
            );
            if (postulantesFiltrados.length > 0) {
              MySwal.fire({
                title: '<strong>Postulación en curso</strong>',
                html: '<i>Estimado postulante, ya cuenta con un registro de inscripción, quedando pendiente el pago por dicho concepto el cual podrá realizarlo en línea a través de los bancos autorizados. <a style="color: blue; text-decoration: underline; cursor: pointer;" href="https://upsjbhelp.freshdesk.com/support/solutions/articles/63000279782-realizar-pagos-online-en-los-bancos-autorizados">Ver</a></i>',
                icon: 'info',
                confirmButtonColor: '#F43F5E',
              });
              return;
            }
          }
        }
      }

      //const recaptcha = await (recaptchaRef?.current as any).executeAsync();
      const data = await registrarPostulante('');

      if (data && data.emplId) {
        if (modalidad.idProceso === modalityIAT.idProceso) {
          setShowModal(true);
          // localStorage.clear()
        } else {
          const deuda = await obtenerPrecio(data.emplId, modalidad.modalidad);
          if (deuda) {
            manager.set(DEUDA_PAGO_KEY, deuda);
            console.log('deuda =>', deuda);
            navigate('/pago');
          }
        }
      } else {
        MySwal.fire({
          title: '<strong>Vaya</strong>',
          html: `<div class='text-justify'>Algo acaba de salir mal, por favor intentalo nuevamente recargando el navegador.</div>`,
          icon: 'info',
          reverseButtons: true,
          confirmButtonColor: buttonColorPink,
          confirmButtonText: `
              Entendido
            `,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (err) {
      console.log('error =>', err);
      addToast('error', MENSAJES.ERROR_DESCONOCIDO);
    } finally {
      setCargandoPostulacion(false);
    }
  };

  useEffect(() => {
    if (!estudios || !requisitos || !modalidad || !datosPersonales) {
      window.location.href = homePath;
      return;
    }
  }, []);

  return (
    <>
      {cargandoPostulacion && <Loading />}

      <div className="flex justify-center mb-5 mt-8">
        <div className="w-10/12 mx-auto">
          <h3 className="text-2xl col-span-2 text-center my-6-per text-lg font-agenda-medium">
            RESUMEN DE INSCRIPCIÓN
          </h3>

          <div className="flex justify-center mb-4 grid grid-cols-1 gap-6 items-center mt-4 pt-10 pb-10">
            <Tabs
              tabs={[
                {
                  name: 'Programa de estudios',
                  content: (
                    <>
                      <p>
                        <strong className="">
                          {modalidad.categoria === categoryResidentado.id
                            ? 'Programa:'
                            : 'Primera opción:'}
                        </strong>{' '}
                        {estudios.carreraSeleccionadaText}
                      </p>
                      <p>
                        <strong className="">Local:</strong>{' '}
                        {estudios.sucursalSeleccionadaText}
                      </p>
                      {modalidad.categoria !== categoryResidentado.id && (
                        <p>
                          <strong className="">Segunda opción:</strong>{' '}
                          {estudios.carrera2daSeleccionadaText
                            ? estudios.carrera2daSeleccionadaText
                            : 'No seleccionado'}
                        </p>
                      )}
                    </>
                  ),
                },
                {
                  name: 'Datos Personales',
                  content: (
                    <>
                      {datosPersonales ? (
                        <div>
                          <p>
                            <strong className=" mr-1">
                              {datosPersonales.tipoDocumentoDescripcion}:
                            </strong>
                            {datosPersonales.numeroDocumento}
                          </p>
                          <p>
                            <strong className="">Nombres:</strong>{' '}
                            {datosPersonales.nombres?.toUpperCase()}
                          </p>
                          <p>
                            <strong className="">Apellidos:</strong>{' '}
                            {datosPersonales.primerApellido?.toUpperCase()}{' '}
                            {datosPersonales.segundoApellido?.toUpperCase()}
                          </p>
                          <p>
                            <strong className="">Número de celular:</strong>{' '}
                            {datosPersonales.celular}
                          </p>
                          <p>
                            <strong className="">Correo electrónico:</strong>{' '}
                            {datosPersonales.correoElectronico?.toUpperCase()}
                          </p>
                          <p>
                            <strong className="">Lugar de nacimiento:</strong>{' '}
                            {datosPersonales.lugarNacimientoDescripcion},{' '}
                            {datosPersonales.paisNacimientoDescripcion}
                          </p>

                          {datosPersonales.fechaNacimiento ? (
                            <p>
                              <strong className="">Fecha de nacimiento:</strong>{' '}
                              {format(
                                new Date(datosPersonales.fechaNacimiento),
                                "dd'/'MM'/'yyyy",
                              )}
                            </p>
                          ) : (
                            <div>
                              {errorData('Fecha de nacimiento no registrada')}{' '}
                            </div>
                          )}

                          <p>
                            <strong className="">Género:</strong>{' '}
                            {datosPersonales.generoDescripcion?.toUpperCase()}
                          </p>

                          <p>
                            <strong className="">Lugar de residencia:</strong>{' '}
                            {datosPersonales.lugarDomicilioDescripcion?.toUpperCase()}
                          </p>

                          <p>
                            <strong className="">Tipo de vía:</strong>{' '}
                            {datosPersonales.tipoViaDescripcion}
                          </p>
                          <p>
                            <strong className="">Domicilio:</strong>{' '}
                            {datosPersonales.domicilio?.toUpperCase()}
                          </p>
                          {datosPersonales.tipoZonaDescripcion && (
                            <p>
                              <strong className="">Tipo de zona:</strong>{' '}
                              {datosPersonales.tipoZonaDescripcion?.toUpperCase()}
                            </p>
                          )}
                          {datosPersonales.domicilioZona && (
                            <p>
                              <strong className="">Zona:</strong>{' '}
                              {datosPersonales.domicilioZona?.toUpperCase()}
                            </p>
                          )}
                          {datosPersonales.referencia && (
                            <p>
                              <strong className="">
                                Referencia de domicilio:
                              </strong>{' '}
                              {datosPersonales.referencia}
                            </p>
                          )}
                        </div>
                      ) : (
                        <div>
                          {errorData('Datos Personales no registrados')}{' '}
                        </div>
                      )}
                    </>
                  ),
                },
                {
                  name: 'Modalidad de admisión',
                  content: (
                    <>
                      {modalidad ? (
                        <>
                          <p>
                            <strong className="">
                              {modalidad.categoriaDescripcion}
                            </strong>
                          </p>
                          {modalidad.modalidad && (
                            <p>{modalidad.modalidadDescripcion}</p>
                          )}
                          {modalidad.categoria ===
                            categoryPregrado.id && (
                              <p>
                                <strong className="">Turno:</strong>{' '}
                                {modalidad.turnoDescripcion}
                              </p>
                            )}
                          {(modalidad.categoria ===
                            categoryPregrado.id) && ( modalidad.turnoSegundaOpcion ) && (
                              <p>
                                <strong className="">Turno Segunda Opción:</strong>{' '}
                                {modalidad.turnoSegundaOpcionDescripcion}
                              </p>
                            )}
                        </>
                      ) : (
                        <div>{errorData('Modalidad no registrada')}</div>
                      )}
                    </>
                  ),
                },
                {
                  name: 'Estudios',
                  content: (
                    <>
                      {requisitos ? (
                        <>
                          <p>
                            <strong className="">Ubicación (Ubigeo):</strong>{' '}
                            {requisitos.lugarColegioDescripcion}
                          </p>
                          <p>
                            <strong className="">Institución Educativa:</strong>{' '}
                            {requisitos.colegioIdDescripcion}
                          </p>
                          <p>
                            <strong className="">Finalización:</strong>{' '}
                            {requisitos.anioFinColegio}
                          </p>
                          <strong>Requisitos:</strong>
                          <ul className="list-disc ml-4">
                            {(archivosRequisitos ?? []).map(
                              (archivoRequisito: RequirementFiles) => (
                                <li key={archivoRequisito.idRequisito}>
                                  {archivoRequisito.nombreRequisito}
                                </li>
                              ),
                            )}
                          </ul>
                        </>
                      ) : (
                        <div>{errorData('Requisitos no registrados')}</div>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </div>

          <div className="mb-10 bg-gray-100 p-8 rounded-xl shadow-md">
            <p className="text-red-600">Nota:</p>
            <>
              <p className="nota text-gray-600 text-justify">
                Todos los requisitos cargados en esta plataforma serán{' '}
                <strong>validados</strong>.
              </p>
              {modalidad.categoria === categoryResidentado.id && (
                <p className="nota text-gray-600 text-justify">
                  Si se encuentra alguna observación, serás notificado de
                  inmediato. Si no se atienden estas observaciones, no podrás
                  continuar en el proceso de Admisión al Residentado Médico
                  2024.
                </p>
              )}
              {modalidad.categoria !== categoryResidentado.id && (
                <p className="nota text-gray-600 text-justify">
                  Si se encuentra alguna observación, serás notificado de
                  inmediato. Si no se atienden estas observaciones, no podrás
                  rendir ninguna evaluación.
                </p>
              )}
              <p className="nota text-gray-600 text-justify">
                En caso de que no hayas podido cargar alguno de los requisitos,
                te enviaremos una comunicación desde el correo de{' '}
                <a className="enlace">
                  {modalidad.categoria === categoryResidentado.id
                    ? 'residentado.medico@upsjb.edu.pe'
                    : 'counter@upsjb.edu.pe'}
                </a>{' '}
                con un enlace para que puedas regularizarlo.
              </p>
            </>
            {modalidad.idProceso === modalityIAT.idProceso && false && (
              <>
                <p className="nota text-gray-600 text-justify">
                  * Los documentos correspondientes a los{' '}
                  <strong>“requisitos”</strong> podrán ser cargados
                  posteriormente en nuestro sistema; sin embargo, los mismos
                  serán verificados. De encontrarse alguna observación serás
                  inmediatamente notificado. De no levantar la(s)
                  observación(es) no podrás rendir tu evaluación.
                </p>
                <p className="nota text-gray-600 text-justify">
                  * Para completar tu inscripción presiona el botón{' '}
                  <strong>CONFIRMAR.</strong>
                </p>
              </>
            )}
            {modalidad.idProceso !== modalityIAT.idProceso && false && (
              <>
                <p className="nota text-gray-600 text-justify">
                  * Los documentos correspondientes a los{' '}
                  <strong>“requisitos”</strong>
                  se han cargado en nuestro sistema; sin embargo, los mismos
                  serán verificados. De encontrarse alguna observación serás
                  inmediatamente notificado. De no levantar la(s)
                  observación(es) no podrás rendir tu evaluación.
                </p>
                <p className="nota text-gray-600 text-justify">
                  * Para completar tu inscripción presiona el botón{' '}
                  <strong>CONFIRMAR.</strong>
                </p>
              </>
            )}
          </div>

          <div className="flex justify-center my-4 gap-x-6">
            <Button
              type="button"
              hoverbackgroundcolor="upsjb-turquoise-hovering"
              onClick={() => {
                navigate('/requisitos?paso=estudios');
              }}
              className="flex items-center"
            >
              <ArrowCircleLeftIcon
                className="text-white rounded-full h-8 w-8 hover:shadow mr-4"
                aria-hidden="true"
              />
              Regresar
            </Button>
            <Button
              hoverbackgroundcolor="upsjb-red-hovering"
              onClick={() => confirmarRegistro()}
              disabled={cargandoPostulacion}
            >
              Confirmar
            </Button>
            <Modal
              width={'sm:max-w-2xl'}
              height={'h-svh-70'}
              open={showModal}
              setOpen={setShowModal}
              leftButtonMessage={'Aceptar'}
              leftButtonDisabled={false}
              showCloseButton={false}
              checkIcon={false}
              onClickLeftButton={async () => {
                window.location.href = 'https://www.upsjb.edu.pe/';
                return true;
              }}
            >
              <div className="">
                <div className="">
                  <img className="w-full" src="img/Img-motivadoraC.png" />
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {false && (
          <ReCAPTCHA
            ref={recaptchaRef as any}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
          />
        )}
      </div>
    </>
  );
};

export default Resumen;
