import { Fragment, PropsWithChildren, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import Button from '../../atoms/Button/Button';

type Props = {
  open: boolean;
  setOpen: (...args: any[]) => void;
  title?: string;
  leftButtonMessage?: string;
  leftButtonDisabled?: boolean;
  onClickLeftButton?: (...args: any[]) => Promise<boolean>;
  rightButtonMessage?: string;
  showCloseButton?: boolean;
  checkIcon?: boolean;
  height?: string;
  width?: string;
};
const Modal = ({
  open,
  setOpen,
  title,
  leftButtonMessage,
  rightButtonMessage,
  onClickLeftButton,
  children,
  leftButtonDisabled = false,
  showCloseButton = true,
  checkIcon = true,
  height,
  width
}: PropsWithChildren<Props>) => {
  let completeButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpen}
        initialFocus={completeButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full ${width} sm:p-4 h-fit`}>
                <div>
                  {showCloseButton ? (
                    <button
                      className="relative w-full"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6 text-gray-400 absolute right-0 -top-5"></XIcon>
                    </button>
                  ) : (
                    <></>
                  )}
                  <div className={`overflow-auto ${height}`}>
                    {checkIcon && <div
                      ref={completeButtonRef}
                      className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                    >
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>}
                    <div className="mt-3 text-center sm:mt-5">
                      {title ? (
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900 text-center"
                        >
                          {title}
                        </Dialog.Title>
                      ) : null}
                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-1 sm:mt-4 md:mt-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
                  <Button
                    hoverbackgroundcolor='upsjb-red-hovering'
                    disabled={leftButtonDisabled}
                    onClick={async () => {
                      if (onClickLeftButton) {
                        const response = await onClickLeftButton();
                        if (response) {
                          setOpen(false);
                        }
                      }
                    }}
                  >
                    {leftButtonMessage}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.defaultProps = {
  width: 'sm:max-w-lg',
  height: 'h-svh-30',
}

export default Modal;
