import { forwardRef, HTMLProps, useId } from 'react';
import type {
  DeepRequired,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import '../Input.css';

export interface TextInputProps {
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<Date>>>;
}

export const TextInput = forwardRef<
  HTMLInputElement,
  TextInputProps & HTMLProps<HTMLInputElement>
>((props: TextInputProps & HTMLProps<HTMLInputElement>, ref) => {
  const inputId = useId();
  return (
    <div className={props.className}>
      {props.label && <label htmlFor={inputId}>
        {props.label}
        {props.required && <strong className='upsjb-color-red'>*</strong>}
      </label>}
      <input ref={ref} {...props} id={inputId} className="Input" autoComplete='off' />
      <div className="text-sm text-red-500 h-2 mt-2">
        {props.error?.message}
      </div>
    </div>
  );
});
TextInput.defaultProps = {
  required: false,
};