
const GlobalHeader = () => {
    return (
        <header className="global-header header upsjb-red">
            <div className="contenedor">
                <div className="headerelementos">
                    <img src="img/logos-upsjb_lineal-blanco.png" alt="UPSJB Logo" />
                    <div className="separador"></div>
                    <p className="headertext font-agenda-medium">Admisión en Línea</p>
                </div>
            </div>
        </header>
    );
};

export default GlobalHeader;


