import axios from 'axios';
const instance = axios.create({
  baseURL: process.env.REACT_APP_SURVEY_BACKEND_URL!,
  timeout: 5000,
});
const APP_ID = 1;
export const obtenerEncuesta = async () => {
  const { data } = await instance.get(`/survey/${APP_ID}`);
  return data;
};

export const subirEncuesta = async ({ answers }: { answers: any }) => {
  await instance.post(`/survey/${APP_ID}`, { answers });
};
