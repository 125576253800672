import { CheckIcon, UploadIcon } from '@heroicons/react/solid';
import { ChangeEventHandler, FC, PropsWithChildren, useEffect, useState } from 'react';
import Button from '../Button/Button';
import IconoConfirmado from "../../molecules/IconoConfirmado/IconoConfirmado";
import { useToast } from '../../hooks/useToast';

export interface FileInputProps {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    fileName?: string
    verified?: boolean;
    mode?: 'grid' | 'lineal';
}

const FileInput: FC<PropsWithChildren<FileInputProps>> = (props) => {
    const [verificado, setVerificado] = useState(props.verified);
    const [fileName, setFileName] = useState<string | null | undefined>(props.fileName);
    const { addToast } = useToast();
    const trigger = (file: any) => {
        if (file) {
            if (file.type !== "application/pdf") {
                setVerificado(false);
                setFileName(null);
                addToast('error', 'Por favor, selecciona un archivo PDF válido.');
                return false;
            } else if (file.size > 5 * 1024 * 1024) {
                setVerificado(false);
                setFileName(null);
                addToast('error', 'El archivo no debe superar los 5MB.');
                return false;
            } else {
                setVerificado(true);
                setFileName(file.name);
                return true;
            }
        }
        return false;
    };
    useEffect(() => {

    }, []);

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (trigger(file)) {
            props.onChange && props.onChange(event);
        } else {
            props.onChange && props.onChange({} as any);
        }

        event.target.value = '';
    }
    return (
        <div className="flex">
            <div className="overflow-hidden relative w-fit">
                <div className="flex content-center">
                    <Button type="button" className={`cursor-pointer w-full inline-flex items-center ${props.mode === 'lineal' ? '!mt-0' : ''}`}>
                        {props.mode === 'grid' &&
                            <UploadIcon className="w-4 h-4 mr-2" />}
                        {(props.mode === 'lineal' && verificado) &&
                            <CheckIcon className="bg-green-500 rounded-full w-4 h-4 mr-2" />}
                        {(props.mode === 'lineal' && !verificado) &&
                            <UploadIcon className="w-4 h-4 mr-2" />}

                        {props.children}
                        <input
                            className="cursor-pointer absolute block opacity-0 pin-r pin-t w-full"
                            id={props.fileName}
                            type="file"
                            name="vacancyImageFiles"
                            onChange={handleFileChange}
                            accept='.pdf'
                        />
                    </Button>
                    {(props.mode === 'grid' && verificado) ? <div className="p-2">
                        <IconoConfirmado />
                    </div> : null}
                </div>
                {/* <div className='text-sm text-gray-400'>{ARCHIVO_LABEL}</div> */}
                <div className='text-sm text-center text-gray-400'>{fileName ? fileName : "Ningún archivo seleccionado"}</div>
            </div>
            {false && <div>{props.fileName}</div>}
        </div>
    );
};

FileInput.defaultProps = {
    mode: 'grid',
}

export default FileInput;
