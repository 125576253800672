import { number, object, string } from 'yup';
import type { InferType } from 'yup';
import { useStorageManager } from '../hooks/useStateManager';
import { DatosPersonalesExtendidos } from './datos-personales.validation';
import { DATOS_PERSONALES_KEY } from '../constants/keys';

export const requisitosValidation = object({
  lugarColegio: string().required(),
  // lugarColegioText: string().optional(),
  colegioId: string().required(),
  // colegioText: string().optional(),
  anioFinColegio: number().min(1900).max(new Date().getFullYear())
  .test('after-birth', 'El año de finalización de los estudios debe ser posterior a la fecha de nacimiento', function(value:any) {
    const manager = useStorageManager();
    const datosPersonales = manager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);
    const birthDate = datosPersonales && new Date(datosPersonales?.fechaNacimiento);
    if (birthDate) {
      const birthYear = birthDate.getFullYear();
      return value >= birthYear;
    }
    console.log("cooooooooooooooo");
    return true;
  })
  .required(),
});

export interface DatosRequisitos
  extends InferType<typeof requisitosValidation> {}

export interface DatosRequisitosExtendidos extends DatosRequisitos {
  colegioIdDescripcion: string;
  lugarColegioDescripcion: string;
}
