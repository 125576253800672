import Button from '../../atoms/Button/Button';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
const ContinueButton = ({
  onClick,
  type = 'button',
}: {
  onClick?: (...args: any[]) => void;
  type?: 'button' | 'submit' | 'reset';
}) => {
  return (
    <Button 
    hoverbackgroundcolor='upsjb-red-hovering'
      className="flex content-between items-center"
      onClick={onClick}
      type={type}
    >
      <div>Continuar</div>
      <ArrowCircleRightIcon
        className="text-white rounded-full h-8 w-8 hover:shadow ml-4"
        aria-hidden="true"
      />
    </Button>
  );
};

export default ContinueButton;
