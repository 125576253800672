import { string } from 'yup';

// TODO: Preguntar qué valor como máximo queda
export const numeroDocumentoValidation = (tipoDocumento:string) => {
  if (tipoDocumento === '01') {
    return string()
      .transform((value: string) => value.replace(/ /g, ''))
      .matches(/^[0-9]{8}$/, 'Solo 8 dígitos numéricos')
      .required();
  } else {
    return string()
      .transform((value: string) => value.replace(/ /g, ''))
      .matches(/^[A-Za-z0-9]{10,20}$/i, 'Min. 10 y máx. 20 caracteres alfanuméricos')
      .required();
  }
};