import { InformationCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Button from '../../atoms/Button/Button';
import { MetodoPago } from '../../models/MetodoPago';
import { TipoComprobante } from '../../models/TipoComprobante';
import { useStorageManager } from '../../hooks/useStateManager';
import { DATOS_PERSONALES_KEY, DEUDA_PAGO_KEY } from '../../constants/keys';
import usjbClient from '../../sdk';
import { DatosPersonalesExtendidos } from '../../validations/datos-personales.validation';
import Loading from './../Loading/loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { buttonColorPink } from '../../utils/strings';

interface detalle {
  item: string;
  descripcion: string;
  fechaVencimiento: string;
  importeDeuda: string;
  tipoItem: string;
  anioAcad: string;
  numeroCuenta: string;
}
interface Deuda {
  emplId: string;
  nombre: string;
  detalleDeuda: detalle[];
}
interface Recibo {
  codigoRespuesta: number;
  mensajeRespuesta: string;
  fechaHoraRespuesta: string;
  idAlumno: string;
  nroRecibo: string;
  totalPagar: number;
  codigoComercio: string;
  tokenSeguridad: string;
  sessionKey: string;
  eticket: string;
  urlJavaScriptBoton: string;
  urlRespuestaAPIRest: string;
}

const Pago = () => {
  const manager = useStorageManager();
  const datosPersonales =
    manager.get<DatosPersonalesExtendidos>(DATOS_PERSONALES_KEY);

  const deuda: Deuda = manager.get<{
    emplId: string;
    nombre: string;
    detalleDeuda: detalle[];
  }>(DEUDA_PAGO_KEY);

  const obtenerRecibo = () => {
    return usjbClient.postulante.generarRecibo({
      idImpl: deuda.emplId,
      email: datosPersonales.correoElectronico,
      nroItem: deuda.detalleDeuda[0].item,
      tipoItem: deuda.detalleDeuda[0].tipoItem,
      importePagar: deuda.detalleDeuda[0].importeDeuda,
      nroCuenta: deuda.detalleDeuda[0].numeroCuenta,
      anhoAcademico: deuda.detalleDeuda[0].anioAcad,
    });
  };
  const somethingBad = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: '<strong>Vaya</strong>',
      html: `<div class='text-justify'>Algo acaba de salir mal, por favor intentalo nuevamente recargando el navegador.</div>`,
      icon: 'info',
      reverseButtons: true,
      confirmButtonColor: buttonColorPink,
      confirmButtonText: `
          Entendido
        `,
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    setIsLoading(false);
  };

  const generarPago = async () => {
    setIsLoading(true);
    try {
      await obtenerRecibo().then((recibo: Recibo) => {
        console.log('recibo=>', recibo);

        const sessionKey = recibo.sessionKey;
        const merchantId = recibo.codigoComercio;
        const ordenId = recibo.nroRecibo;
        const id = recibo.eticket;

        setTimeout(() => {
          try {
            var scriptVisa = document.createElement('script');
            scriptVisa.src =
              'https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true';
            //scriptVisa.src='https://static-content.vnforapps.com/v2/js/checkout.js';
            scriptVisa.dataset.sessiontoken = sessionKey;
            scriptVisa.dataset.channel = 'web';
            scriptVisa.dataset.merchantid = merchantId.toString();
            scriptVisa.dataset.merchantlogo =
              'https://intranet.upsjb.edu.pe/assets/images/logos-upsjb_2022.png';
            scriptVisa.dataset.formbuttoncolor = '#F43F5E';
            scriptVisa.dataset.usertoken = recibo.idAlumno.toString();
            scriptVisa.dataset.purchasenumber = ordenId;
            scriptVisa.dataset.amount = recibo.totalPagar.toString();
            scriptVisa.dataset.expirationminutes = '5';
            scriptVisa.dataset.timeouturl = 'urlTimeoutVisa';
            console.log('scriptVisa.dataset', scriptVisa.dataset);
            //scriptVisa.setAttribute('data-formbuttoncolor', '#F43F5E');
            //document.querySelector('#frmInvocaVisa')?.setAttribute('action', 'https://app.upsjb.edu.pe/apisIntegracionesNiubiz/api/v1/integracion/pasarela/contabilizar-recibo-admision/' + id);
            document
              .querySelector('#frmInvocaVisa')
              ?.setAttribute(
                'action',
                `${process.env.REACT_APP_BACKEND_URL}/apisIntegracionesNiubiz/api/v1/integracion/pasarela/contabilizar-recibo-admision/` +
                  id,
              );
            document.querySelector('#frmInvocaVisa')?.appendChild(scriptVisa);
            setPanelVisible(true);
            setIsLoading(false);
          } catch (error) {
            somethingBad();
          }
        }, 8000);
      });
    } catch (e) {
      somethingBad();
      console.log('mal', e);
    } finally {
    }
  };

  const [tipoComprobante, setTipoComprobante] =
    useState<TipoComprobante | null>(null);
  const [metodoPago, setMetodoPago] = useState<MetodoPago | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [panelVisible, setPanelVisible] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <div className="flex justify-center">
        <div className="m-10 grid grid-cols-2 gap-6 md:w-1/2">
          <h1 className="text-4xl text-center col-span-2 font-bold text-rose-500 mb-6">
            PAGO DE INSCRIPCIÓN
          </h1>
          <div className="bg-white border rounded-lg p-4 col-span-2">
            <div className="grid grid-cols-2 gap-4">
              <div className="text-lg font-semibold">Descripción:</div>
              <div className="text-lg">{deuda.detalleDeuda[0].descripcion}</div>
              <div className="text-lg font-semibold">Total a pagar:</div>
              <div className="text-lg">
                S/{deuda.detalleDeuda[0].importeDeuda}
              </div>
            </div>
          </div>

          {/* Panel informativo */}
          {panelVisible && (
            <div className="col-span-2 mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 rounded">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-8 w-8 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 text-justify">
                  <p
                    className="text-sm font-bold"
                    style={{ fontSize: '1.15em' }}
                  >
                    Genial! Hemos generado la deuda de forma satisfactoria.
                  </p>
                  <p>
                    Ahora puedes proceder con el pago de la inscripción en el
                    boton <strong>Pay Here</strong>.
                  </p>
                  <p>
                    Recuerda no cerrar esta ventana hasta que finalice el
                    proceso de pago.
                  </p>
                  <p>
                    También puedes realizar el pago en cualquier agencia de los
                    bancos BBVA, BCP, Scotiabank y BanBif.
                  </p>
                </div>
              </div>
            </div>
          )}
          {!panelVisible && (
            <div className="mt-10 col-span-2 ">
              <div className=" bg-gray-100 p-8 rounded-xl shadow-md col-span-2">
                <p className="text-red-600">Importante:</p>
                <p className="nota text-gray-600 text-justify">
                  Se registrará tu postulación al dar clic sobre{' '}
                  <strong>Generar Pago</strong> y a continuación te mostraremos
                  las opciones de pago
                </p>
              </div>
            </div>
          )}
          {/* Fin del panel informativo */}
        </div>
      </div>
      {!panelVisible && (
        <div className="flex justify-center">
          <Button
            hoverbackgroundcolor="upsjb-red-hovering"
            onClick={() => generarPago()}
            className="flex items-center text-center"
          >
            Generar Pago
          </Button>
        </div>
      )}
      <div className="flex justify-center">
        <form id="frmInvocaVisa" action="" method="post"></form>
      </div>
    </>
  );
};

export default Pago;
