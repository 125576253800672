import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const ErrorPago = () => {
  const { code, status,mensaje } = useParams();
  const navigate = useNavigate();
  const {
    formState: { errors },
  } = useForm({ mode: 'all' });
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    const init = async () => {
      MySwal.fire({
        title: '<h2 class="text-xl col-span-2 text-center my-6 text-rose-500">¡Transacción Fallida!</h2>',
        html: `
          <div class="confirmation-box border p-6 mt-6">
            <p class="mb-4">¡Tu pago no ha sido procesado!</p>
            <p><strong>Codigo:</strong>  ${code}</p>
            <p><strong>Estatus:</strong>  ${status}</p>
            <p><strong>Mensaje:</strong> ${mensaje}</p>
          </div>
        `,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/');
        }
      });

    };
    init();
  }, []);
  return (
    <>
       <div className="flex justify-center">
        <div>
            <h2 className="text-xl col-span-2 text-center my-6 text-rose-500">
                RESUMEN DE PAGO
            </h2>
            <h2 className="text-2xl col-span-2 text-center my-6 text-red-500">
                Transacción Erronea
            </h2>
            <div className="confirmation-box border p-6 mt-6">
                <p className="mb-4">¡Tu pago no ha sido procesado!</p>
                <p><strong>Codigo:</strong>  {code} </p>
                <p><strong>Estatus:</strong>  {status} </p>
                <p><strong>Mensaje:</strong> {mensaje}</p>
            </div>
        </div>
    </div>
    </>
  );
};

export default ErrorPago;
