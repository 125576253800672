import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ToastContainer, ToastProps, ToastType } from '../molecules/Toast/Toast';

export interface ToastContextValue {
  addToast: (type: ToastType, text: string) => void;
  removeToast: (id: number) => void;
}
export const ToastContext = createContext<ToastContextValue>({
  addToast: (type, text) => text,
  removeToast: (id: number) => id,
});

let id = 0;

const ToastProvider: FC<PropsWithChildren> = (props) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const addToast = useCallback(
    (type: ToastType, text: string) => {
      setToasts((toasts) => [
        ...toasts,
        {
          type,
          text,
          id: id++,
        },
      ]);
    },
    [setToasts],
  );
  const removeToast = useCallback(
    (id: number) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts],
  );
  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {props.children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export default ToastProvider;
