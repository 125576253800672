import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StepperWrapper from './molecules/StepperWrapper/StepperWrapper';
import { DatosPersonalesPage, Estudios, Inicio, InicioPregrado } from './pages';
import Encuesta from './pages/Encuesta/Encuesta';
import InfoInscripcion from './pages/InfoInscripcion/InfoInscripcion';
import InicioSesion from './pages/InicioSesion/InicioSesion';
import ModalidadAdmision from './pages/ModalidadAdmision/ModalidadAdmision';
import Pago from './pages/Pago/Pago';
import Requisitos from './pages/Requisitos/Requisitos';
import Resumen from './pages/Resumen/Resumen';
import ToastProvider from './hooks/useToast';
import LogoWrapper from './molecules/LogoWrapper/LogoWrapper';
import ResumenPago from './pages/ResumenPago/ResumenPago';
import ErrorPago from './pages/ErrorPago/ResumenPago';
import { homePath } from './utils/strings';

const App = () => {
  return (
    <div className="">
      <ToastProvider>
        <Router basename={homePath}>
          <Routes>
            <Route element={<LogoWrapper />}>
              <Route path="/" element={<App />} />
              <Route index element={<Inicio />} />
              <Route path="/login" element={<InicioSesion />} />
              <Route path="/pregrado" element={<InicioPregrado />} />
              <Route path="/info-inscripcion" element={<InfoInscripcion />} />
              <Route path="/encuesta" element={<Encuesta />} />
            </Route>
            <Route element={<StepperWrapper />}>
              <Route
                path="/datos-personales"
                element={<DatosPersonalesPage />}
              />
              <Route
                path="/modalidades-pregrado"
                element={<ModalidadAdmision />}
              />
              <Route path="/estudios" element={<Estudios />} />
              <Route path="/requisitos" element={<Requisitos />} />
              <Route path="/resumen" element={<Resumen />} />
              <Route path="/pago" element={<Pago />} />
              <Route path="/resumen-pago/:id/:nrorecibo/:fecha/:mensaje" element={<ResumenPago />} />
              <Route path="/error-pago/:code/:status/:mensaje" element={<ErrorPago />} />
            </Route>
          </Routes>
        </Router>
      </ToastProvider>
    </div>
  );
};

export default App;
