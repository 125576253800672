import usjbClient from '../../sdk';

export const obtenerUbigeo = async () => {
  const { listaUbigeo: lugarNacimiento } =
    await usjbClient.ubigeo.obtenerUbigeo();
  return lugarNacimiento.map(
    ({ ubigeo: text, valor: value }: { ubigeo: string; valor: string }) => ({
      text,
      value,
    }),
  );
};
