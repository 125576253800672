import { Link } from 'react-router-dom';

const Inicio = () => {
  localStorage.clear();
  return (
    <div className="m-8">
      <div className="md:container mx-auto lg:container ">
        <div className="grid md:grid-cols-2">
          <div className="bloquetext">
            <h2 className="font-agenda-bold text-center text-2xl md:text-4xl">
              Bienvenido a <br /> Admisión en Línea!
            </h2>
            <p className="text-base">
              <strong>
                ¿Listo para dar el primer paso hacia tu futuro académico?
              </strong>
            </p>
            <p className="mt-4 text-base text-justify	">
            En esta plataforma, podrás inscribirte al proceso de admisión de la San Juan de manera rápida y sencilla. 
            Tu privacidad es nuestra prioridad, nuestra plataforma es <strong>100% segura.</strong>
            </p>
            <p className="text-base mt-4 text-justify	">
              Antes de comenzar, te recomendamos revisar los{' '}
              <strong>requisitos de inscripción</strong> según la modalidad de
              admisión que elijas. Así podrás estar preparado y asegurarte de
              cumplir con todo lo necesario para un registro exitoso.
            </p>
            <p className="text-base mt-4 text-justify	">
              ¡Adelante! Tu futuro está a solo unos clics de distancia.
            </p>
            {false && (
              <div className=" flex flex-col justify-center items-center">
                <div className="pasos mt-8 sm:w-full md:w-1/2 ">
                  <div className="pago flex mb-3">
                    <div className="w-6">
                      <img
                        className="w-4"
                        src="img/icons/arrow-pointer-solid.svg"
                        alt=""
                      />
                    </div>
                    <p className=" text-xl pl-4">
                      Realiza el pago en solo 4 pasos
                    </p>
                  </div>
                  <div className="card flex justify-items-start	 ">
                    <div className="w-6">
                      <img
                        className="w-6"
                        src="img/icons/credit-card-regular.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-xl pl-4">Plataforma 100% segura</p>
                  </div>
                </div>
              </div>
            )}
            <div className="bottom-0 fixed sm:static">
              <div className="flex justify-center pt-12 pb-8">
                <Link to="pregrado" className="w-fit">
                  <span className="font-agenda-bold rounded-md botoninscribirse upsjb-border-red upsjb-red text-white border-[3px] pt-4 pb-3 px-8 upsjb-color-red text-2xl	">
                    QUIERO INSCRIBIRME
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-8 md:mt-0 bloqueimage flex justify-center">
            <div className="w-full md:w-4/5 lg:w-5/6">
              <img src="img/people/alumnos.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <Link to="/login" className="absolute top-4 right-4 invisible md:visible">
        <Button>Consulta tu postulación</Button>
      </Link> */}
      {/* <p className="text-2xl text-center">Elige la opción que prefieras</p> */}
    </div>
  );
};

export default Inicio;
